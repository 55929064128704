/* PrivateRoute.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	create_component,
	create_slot,
	destroy_component,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "./_snowpack/pkg/svelte/internal.js";

import { Route } from "./_snowpack/pkg/svelte-navigator.js";
import PrivateRouteGuard from "./PrivateRouteGuard.svelte.js";

const get_default_slot_changes = dirty => ({
	params: dirty & /*params*/ 8,
	location: dirty & /*location*/ 16,
	navigate: dirty & /*navigate*/ 32
});

const get_default_slot_context = ctx => ({
	params: /*params*/ ctx[3],
	location: /*location*/ ctx[4],
	navigate: /*navigate*/ ctx[5]
});

// (9:2) <PrivateRouteGuard>
function create_default_slot_1(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[1].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], get_default_slot_context);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, params, location, navigate*/ 60)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, get_default_slot_changes),
						get_default_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (8:0) <Route {path} let:params let:location let:navigate>
function create_default_slot(ctx) {
	let privaterouteguard;
	let current;

	privaterouteguard = new PrivateRouteGuard({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(privaterouteguard.$$.fragment);
		},
		m(target, anchor) {
			mount_component(privaterouteguard, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const privaterouteguard_changes = {};

			if (dirty & /*$$scope, params, location, navigate*/ 60) {
				privaterouteguard_changes.$$scope = { dirty, ctx };
			}

			privaterouteguard.$set(privaterouteguard_changes);
		},
		i(local) {
			if (current) return;
			transition_in(privaterouteguard.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(privaterouteguard.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(privaterouteguard, detaching);
		}
	};
}

function create_fragment(ctx) {
	let route;
	let current;

	route = new Route({
			props: {
				path: /*path*/ ctx[0],
				$$slots: {
					default: [
						create_default_slot,
						({ params, location, navigate }) => ({ 3: params, 4: location, 5: navigate }),
						({ params, location, navigate }) => (params ? 8 : 0) | (location ? 16 : 0) | (navigate ? 32 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(route.$$.fragment);
		},
		m(target, anchor) {
			mount_component(route, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const route_changes = {};
			if (dirty & /*path*/ 1) route_changes.path = /*path*/ ctx[0];

			if (dirty & /*$$scope, params, location, navigate*/ 60) {
				route_changes.$$scope = { dirty, ctx };
			}

			route.$set(route_changes);
		},
		i(local) {
			if (current) return;
			transition_in(route.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(route.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(route, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { path } = $$props;

	$$self.$$set = $$props => {
		if ('path' in $$props) $$invalidate(0, path = $$props.path);
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [path, slots, $$scope];
}

class PrivateRoute extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { path: 0 });
	}
}

export default PrivateRoute;