/* Demo.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_store_value,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out
} from "./_snowpack/pkg/svelte/internal.js";

import { onMount } from './_snowpack/pkg/svelte.js';
import { user } from "./stores.js";
import { user_address } from "./stores.js";
import { Router, Route, Link } from "./_snowpack/pkg/svelte-navigator.js";
import { useParams, useNavigate, useLocation } from "./_snowpack/pkg/svelte-navigator.js";

//https://github.com/Hejtmus/svelte-lightbox
import { Lightbox } from './_snowpack/pkg/svelte-lightbox.js';

function create_else_block_7(ctx) {
	let div0;
	let label0;
	let input0;
	let input0_checked_value;
	let t0;
	let t1;
	let div1;
	let label1;
	let input1;
	let input1_checked_value;
	let t2;
	let t3;
	let div2;
	let label2;
	let input2;
	let input2_checked_value;
	let t4;
	let t5;
	let div3;
	let label3;
	let input3;
	let input3_checked_value;
	let t6;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t0 = text("\n\t\t\t\t\tPakett 1 / A");
			t1 = space();
			div1 = element("div");
			label1 = element("label");
			input1 = element("input");
			t2 = text("\n\t\t\t\t\tPakett 1 / B");
			t3 = space();
			div2 = element("div");
			label2 = element("label");
			input2 = element("input");
			t4 = text("\n\t\t\t\t\tPakett 2 / A - Lisatasu 3300 Eur");
			t5 = space();
			div3 = element("div");
			label3 = element("label");
			input3 = element("input");
			t6 = text("\n\t\t\t\t\tPakett 2 / B - Lisatasu 3300 Eur");
			attr(input0, "type", "radio");
			attr(input0, "name", "plaadid");
			input0.checked = input0_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 1 / A";
			input0.value = "Pakett 1 / A";
			attr(label0, "class", "radio");
			attr(div0, "class", "control");
			attr(input1, "type", "radio");
			attr(input1, "name", "plaadid");
			input1.checked = input1_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 1 / B";
			input1.value = "Pakett 1 / B";
			attr(label1, "class", "radio");
			attr(div1, "class", "control");
			attr(input2, "type", "radio");
			attr(input2, "name", "plaadid");
			input2.checked = input2_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 2 / A";
			input2.value = "Pakett 2 / A";
			attr(label2, "class", "radio");
			attr(div2, "class", "control");
			attr(input3, "type", "radio");
			attr(input3, "name", "plaadid");
			input3.checked = input3_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 2 / B";
			input3.value = "Pakett 2 / B";
			attr(label3, "class", "radio");
			attr(div3, "class", "control");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, label0);
			append(label0, input0);
			append(label0, t0);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, label1);
			append(label1, input1);
			append(label1, t2);
			insert(target, t3, anchor);
			insert(target, div2, anchor);
			append(div2, label2);
			append(label2, input2);
			append(label2, t4);
			insert(target, t5, anchor);
			insert(target, div3, anchor);
			append(div3, label3);
			append(label3, input3);
			append(label3, t6);

			if (!mounted) {
				dispose = [
					listen(input0, "change", /*onChange_plaadid*/ ctx[3]),
					listen(input1, "change", /*onChange_plaadid*/ ctx[3]),
					listen(input2, "change", /*onChange_plaadid*/ ctx[3]),
					listen(input3, "change", /*onChange_plaadid*/ ctx[3])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && input0_checked_value !== (input0_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 1 / A")) {
				input0.checked = input0_checked_value;
			}

			if (dirty & /*interior*/ 1 && input1_checked_value !== (input1_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 1 / B")) {
				input1.checked = input1_checked_value;
			}

			if (dirty & /*interior*/ 1 && input2_checked_value !== (input2_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 2 / A")) {
				input2.checked = input2_checked_value;
			}

			if (dirty & /*interior*/ 1 && input3_checked_value !== (input3_checked_value = /*interior*/ ctx[0].plaadid === "Pakett 2 / B")) {
				input3.checked = input3_checked_value;
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			if (detaching) detach(t3);
			if (detaching) detach(div2);
			if (detaching) detach(t5);
			if (detaching) detach(div3);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (126:3) {#if interior.is_interior_inactive}
function create_if_block_15(ctx) {
	let p;
	let t_value = /*interior*/ ctx[0].plaadid + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].plaadid + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (165:5) 
function create_thumbnail_slot_4(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "thumbnail");
			if (!src_url_equal(img.src, img_src_value = "./img/pakett 1.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Thumbnail");
			attr(img, "width", "240");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (166:5) 
function create_image_slot_4(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "image");
			if (!src_url_equal(img.src, img_src_value = "./img/pakett 1.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Lightbox image");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (171:5) 
function create_thumbnail_slot_3(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "thumbnail");
			if (!src_url_equal(img.src, img_src_value = "./img/pakett 2.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Thumbnail");
			attr(img, "width", "240");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (172:5) 
function create_image_slot_3(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "image");
			if (!src_url_equal(img.src, img_src_value = "./img/pakett 2.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Lightbox image");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (199:3) {:else}
function create_else_block_6(ctx) {
	let div0;
	let label0;
	let input0;
	let input0_checked_value;
	let t0;
	let t1;
	let div1;
	let label1;
	let input1;
	let input1_checked_value;
	let t2;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t0 = text("\n\t\t\t\t\tValamukapp tume");
			t1 = space();
			div1 = element("div");
			label1 = element("label");
			input1 = element("input");
			t2 = text("\n\t\t\t\t\tValamukapp valge");
			attr(input0, "type", "radio");
			attr(input0, "name", "santehnika");
			input0.checked = input0_checked_value = /*interior*/ ctx[0].santehnika === "Valamukapp tume";
			input0.value = "Valamukapp tume";
			attr(label0, "class", "radio");
			attr(div0, "class", "control");
			attr(input1, "type", "radio");
			attr(input1, "name", "santehnika");
			input1.checked = input1_checked_value = /*interior*/ ctx[0].santehnika === "Valamukapp valge";
			input1.value = "Valamukapp valge";
			attr(label1, "class", "radio");
			attr(div1, "class", "control");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, label0);
			append(label0, input0);
			append(label0, t0);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, label1);
			append(label1, input1);
			append(label1, t2);

			if (!mounted) {
				dispose = [
					listen(input0, "change", /*onChange_santehnika*/ ctx[4]),
					listen(input1, "change", /*onChange_santehnika*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && input0_checked_value !== (input0_checked_value = /*interior*/ ctx[0].santehnika === "Valamukapp tume")) {
				input0.checked = input0_checked_value;
			}

			if (dirty & /*interior*/ 1 && input1_checked_value !== (input1_checked_value = /*interior*/ ctx[0].santehnika === "Valamukapp valge")) {
				input1.checked = input1_checked_value;
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (195:3) {#if interior.is_interior_inactive}
function create_if_block_14(ctx) {
	let p;
	let t_value = /*interior*/ ctx[0].santehnika + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].santehnika + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (223:5) 
function create_thumbnail_slot_2(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "thumbnail");
			if (!src_url_equal(img.src, img_src_value = "./img/san tehnika.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Thumbnail");
			attr(img, "width", "240");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (224:5) 
function create_image_slot_2(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "image");
			if (!src_url_equal(img.src, img_src_value = "./img/san tehnika.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Lightbox image");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (255:3) {:else}
function create_else_block_5(ctx) {
	let div0;
	let label0;
	let input0;
	let input0_checked_value;
	let t0;
	let t1;
	let div1;
	let label1;
	let input1;
	let input1_checked_value;
	let t2;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t0 = text("\n\t\t\t\t\tValge põrandaliist");
			t1 = space();
			div1 = element("div");
			label1 = element("label");
			input1 = element("input");
			t2 = text("\n\t\t\t\t\tTammevärvi põrandaliist");
			attr(input0, "type", "radio");
			attr(input0, "name", "liist");
			input0.checked = input0_checked_value = /*interior*/ ctx[0].liist === "Valge";
			input0.value = "Valge";
			attr(label0, "class", "radio");
			attr(div0, "class", "control");
			attr(input1, "type", "radio");
			attr(input1, "name", "liist");
			input1.checked = input1_checked_value = /*interior*/ ctx[0].liist === "Tammevärvi";
			input1.value = "Tammevärvi";
			attr(label1, "class", "radio");
			attr(div1, "class", "control");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, label0);
			append(label0, input0);
			append(label0, t0);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, label1);
			append(label1, input1);
			append(label1, t2);

			if (!mounted) {
				dispose = [
					listen(input0, "change", /*onChange_liist*/ ctx[5]),
					listen(input1, "change", /*onChange_liist*/ ctx[5])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && input0_checked_value !== (input0_checked_value = /*interior*/ ctx[0].liist === "Valge")) {
				input0.checked = input0_checked_value;
			}

			if (dirty & /*interior*/ 1 && input1_checked_value !== (input1_checked_value = /*interior*/ ctx[0].liist === "Tammevärvi")) {
				input1.checked = input1_checked_value;
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (251:3) {#if interior.is_interior_inactive}
function create_if_block_13(ctx) {
	let p;
	let t_value = /*interior*/ ctx[0].liist + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].liist + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (279:5) 
function create_thumbnail_slot_1(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "thumbnail");
			if (!src_url_equal(img.src, img_src_value = "./img/parket uks elekter.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Thumbnail");
			attr(img, "width", "240");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (280:5) 
function create_image_slot_1(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "image");
			if (!src_url_equal(img.src, img_src_value = "./img/parket uks elekter.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Lightbox image");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (310:3) {:else}
function create_else_block_4(ctx) {
	let div0;
	let label0;
	let input0;
	let input0_checked_value;
	let t0;
	let t1;
	let div1;
	let label1;
	let input1;
	let input1_checked_value;
	let t2;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t0 = text("\n\t\t\t\t\tSpoon siseuks");
			t1 = space();
			div1 = element("div");
			label1 = element("label");
			input1 = element("input");
			t2 = text("\n\t\t\t\t\tSile valge siseuks");
			attr(input0, "type", "radio");
			attr(input0, "name", "siseuks");
			input0.checked = input0_checked_value = /*interior*/ ctx[0].siseuks === "Spoon";
			input0.value = "Spoon";
			attr(label0, "class", "radio");
			attr(div0, "class", "control");
			attr(input1, "type", "radio");
			attr(input1, "name", "siseuks");
			input1.checked = input1_checked_value = /*interior*/ ctx[0].siseuks === "Sile valge";
			input1.value = "Sile valge";
			attr(label1, "class", "radio");
			attr(div1, "class", "control");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, label0);
			append(label0, input0);
			append(label0, t0);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, label1);
			append(label1, input1);
			append(label1, t2);

			if (!mounted) {
				dispose = [
					listen(input0, "change", /*onChange_siseuks*/ ctx[11]),
					listen(input1, "change", /*onChange_siseuks*/ ctx[11])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && input0_checked_value !== (input0_checked_value = /*interior*/ ctx[0].siseuks === "Spoon")) {
				input0.checked = input0_checked_value;
			}

			if (dirty & /*interior*/ 1 && input1_checked_value !== (input1_checked_value = /*interior*/ ctx[0].siseuks === "Sile valge")) {
				input1.checked = input1_checked_value;
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (306:3) {#if interior.is_interior_inactive}
function create_if_block_12(ctx) {
	let p;
	let t_value = /*interior*/ ctx[0].siseuks + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].siseuks + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (334:5) 
function create_thumbnail_slot(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "thumbnail");
			if (!src_url_equal(img.src, img_src_value = "./img/parket uks elekter.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Thumbnail");
			attr(img, "width", "240");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (335:5) 
function create_image_slot(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "slot", "image");
			if (!src_url_equal(img.src, img_src_value = "./img/parket uks elekter.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Lightbox image");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (364:4) {:else}
function create_else_block_3(ctx) {
	let div;
	let input;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			input = element("input");
			attr(input, "class", "input");
			attr(input, "type", "text");
			attr(input, "placeholder", "");
			attr(div, "class", "control");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, input);
			set_input_value(input, /*interior*/ ctx[0].seinavarv);

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler*/ ctx[13]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && input.value !== /*interior*/ ctx[0].seinavarv) {
				set_input_value(input, /*interior*/ ctx[0].seinavarv);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (360:4) {#if interior.is_interior_inactive}
function create_if_block_11(ctx) {
	let p;
	let t_value = /*interior*/ ctx[0].seinavarv + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].seinavarv + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (370:3) {#if !interior.is_interior_inactive}
function create_if_block_10(ctx) {
	let div;
	let label;
	let input;
	let t;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			label = element("label");
			input = element("input");
			t = text("\n\t\t\t\t\tSoovin värvi täpsustada tubade kaupa");
			attr(input, "type", "checkbox");
			input.__value = "Y";
			input.value = input.__value;
			attr(label, "class", "checkbox");
			attr(div, "class", "field");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, label);
			append(label, input);
			input.checked = /*interior*/ ctx[0].seinavarv_is_uncommon;
			append(label, t);

			if (!mounted) {
				dispose = [
					listen(input, "change", /*input_change_handler*/ ctx[14]),
					listen(input, "click", /*handle_Chg_seinavarv_is_uncommon*/ ctx[12])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1) {
				input.checked = /*interior*/ ctx[0].seinavarv_is_uncommon;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (378:3) {#if interior.seinavarv_is_uncommon}
function create_if_block_8(ctx) {
	let div;
	let label;
	let t1;

	function select_block_type_5(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_9;
		return create_else_block_2;
	}

	let current_block_type = select_block_type_5(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div = element("div");
			label = element("label");
			label.textContent = "Värvi täpsustused";
			t1 = space();
			if_block.c();
			attr(label, "class", "label");
			attr(div, "class", "field");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, label);
			append(div, t1);
			if_block.m(div, null);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_5(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if_block.d();
		}
	};
}

// (385:4) {:else}
function create_else_block_2(ctx) {
	let textarea;
	let mounted;
	let dispose;

	return {
		c() {
			textarea = element("textarea");
			attr(textarea, "class", "textarea");
			attr(textarea, "placeholder", "");
			attr(textarea, "rows", "6");
		},
		m(target, anchor) {
			insert(target, textarea, anchor);
			set_input_value(textarea, /*interior*/ ctx[0].seinavarv_details);

			if (!mounted) {
				dispose = listen(textarea, "input", /*textarea_input_handler*/ ctx[15]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1) {
				set_input_value(textarea, /*interior*/ ctx[0].seinavarv_details);
			}
		},
		d(detaching) {
			if (detaching) detach(textarea);
			mounted = false;
			dispose();
		}
	};
}

// (381:4) {#if interior.is_interior_inactive}
function create_if_block_9(ctx) {
	let p;
	let t_value = /*interior*/ ctx[0].seinavarv_details + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].seinavarv_details + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (425:3) {:else}
function create_else_block_1(ctx) {
	let div0;
	let label0;
	let input0;
	let input0_checked_value;
	let t0;
	let t1;
	let div1;
	let label1;
	let input1;
	let input1_checked_value;
	let t2;
	let t3;
	let div2;
	let label2;
	let input2;
	let input2_checked_value;
	let t4;
	let t5;
	let div3;
	let label3;
	let input3;
	let input3_checked_value;
	let t6;
	let t7;
	let div4;
	let label4;
	let input4;
	let input4_checked_value;
	let t8;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t0 = text("\n\t\t\t\t\tKöögimööbel koos tehnikaga ning paigaldusega L-kujuline - 10500 Eur");
			t1 = space();
			div1 = element("div");
			label1 = element("label");
			input1 = element("input");
			t2 = text("\n\t\t\t\t\tJahutussüsteem (ainult elutuba) - 8200 Eur");
			t3 = space();
			div2 = element("div");
			label2 = element("label");
			input2 = element("input");
			t4 = text("\n\t\t\t\t\tPlaadipakett 2 (LUX) - 3300 Eur");
			t5 = space();
			div3 = element("div");
			label3 = element("label");
			input3 = element("input");
			t6 = text("\n\t\t\t\t\tVann Balteco 16 koos paigaldusega ning vannisegisti Hansgrohe - 1600 Eur");
			t7 = space();
			div4 = element("div");
			label4 = element("label");
			input4 = element("input");
			t8 = text("\n\t\t\t\t\tLeiliruumi ehitus - 2800 Eur");
			attr(input0, "type", "checkbox");
			attr(input0, "name", "kook");
			input0.checked = input0_checked_value = /*interior*/ ctx[0].kook === "L-kujuline - 10500 Eur";
			input0.value = "L-kujuline - 10500 Eur";
			attr(label0, "class", "checkbox");
			attr(div0, "class", "control");
			attr(input1, "type", "checkbox");
			attr(input1, "name", "jahutus");
			input1.checked = input1_checked_value = /*interior*/ ctx[0].jahutus === "(ainult elutuba) - 8200 Eur";
			input1.value = "(ainult elutuba) - 8200 Eur";
			attr(label1, "class", "checkbox");
			attr(div1, "class", "control");
			attr(input2, "type", "checkbox");
			attr(input2, "name", "pladipakett");
			input2.checked = input2_checked_value = /*interior*/ ctx[0].pladipakett === "Plaadipakett 2 (LUX) - 3300 Eur";
			input2.value = "Plaadipakett 2 (LUX) - 3300 Eur";
			attr(label2, "class", "checkbox");
			attr(div2, "class", "control");
			attr(input3, "type", "checkbox");
			attr(input3, "name", "vann");
			input3.checked = input3_checked_value = /*interior*/ ctx[0].vann === "Vann Balteco - 1600 Eur";
			input3.value = "Vann Balteco - 1600 Eur";
			attr(label3, "class", "checkbox");
			attr(div3, "class", "control");
			attr(input4, "type", "checkbox");
			attr(input4, "name", "leiliruum");
			input4.checked = input4_checked_value = /*interior*/ ctx[0].leiliruum === "Leiliruumi ehitus - 2800 Eur";
			input4.value = "Leiliruumi ehitus - 2800 Eur";
			attr(label4, "class", "checkbox");
			attr(div4, "class", "control");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, label0);
			append(label0, input0);
			append(label0, t0);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, label1);
			append(label1, input1);
			append(label1, t2);
			insert(target, t3, anchor);
			insert(target, div2, anchor);
			append(div2, label2);
			append(label2, input2);
			append(label2, t4);
			insert(target, t5, anchor);
			insert(target, div3, anchor);
			append(div3, label3);
			append(label3, input3);
			append(label3, t6);
			insert(target, t7, anchor);
			insert(target, div4, anchor);
			append(div4, label4);
			append(label4, input4);
			append(label4, t8);

			if (!mounted) {
				dispose = [
					listen(input0, "change", /*onChange_kook*/ ctx[6]),
					listen(input1, "change", /*onChange_jahutus*/ ctx[7]),
					listen(input2, "change", /*onChange_plaadipakett*/ ctx[8]),
					listen(input3, "change", /*onChange_vann*/ ctx[9]),
					listen(input4, "change", /*onChange_leiliruum*/ ctx[10])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && input0_checked_value !== (input0_checked_value = /*interior*/ ctx[0].kook === "L-kujuline - 10500 Eur")) {
				input0.checked = input0_checked_value;
			}

			if (dirty & /*interior*/ 1 && input1_checked_value !== (input1_checked_value = /*interior*/ ctx[0].jahutus === "(ainult elutuba) - 8200 Eur")) {
				input1.checked = input1_checked_value;
			}

			if (dirty & /*interior*/ 1 && input2_checked_value !== (input2_checked_value = /*interior*/ ctx[0].pladipakett === "Plaadipakett 2 (LUX) - 3300 Eur")) {
				input2.checked = input2_checked_value;
			}

			if (dirty & /*interior*/ 1 && input3_checked_value !== (input3_checked_value = /*interior*/ ctx[0].vann === "Vann Balteco - 1600 Eur")) {
				input3.checked = input3_checked_value;
			}

			if (dirty & /*interior*/ 1 && input4_checked_value !== (input4_checked_value = /*interior*/ ctx[0].leiliruum === "Leiliruumi ehitus - 2800 Eur")) {
				input4.checked = input4_checked_value;
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			if (detaching) detach(t3);
			if (detaching) detach(div2);
			if (detaching) detach(t5);
			if (detaching) detach(div3);
			if (detaching) detach(t7);
			if (detaching) detach(div4);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (407:3) {#if interior.is_interior_inactive}
function create_if_block_2(ctx) {
	let p;
	let t0;
	let t1;
	let t2;
	let t3;
	let if_block0 = /*interior*/ ctx[0].kook && create_if_block_7(ctx);
	let if_block1 = /*interior*/ ctx[0].jahutus && create_if_block_6(ctx);
	let if_block2 = /*interior*/ ctx[0].pladipakett && create_if_block_5(ctx);
	let if_block3 = /*interior*/ ctx[0].vann && create_if_block_4(ctx);
	let if_block4 = /*interior*/ ctx[0].leiliruum && create_if_block_3(ctx);

	return {
		c() {
			p = element("p");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			t2 = space();
			if (if_block3) if_block3.c();
			t3 = space();
			if (if_block4) if_block4.c();
		},
		m(target, anchor) {
			insert(target, p, anchor);
			if (if_block0) if_block0.m(p, null);
			append(p, t0);
			if (if_block1) if_block1.m(p, null);
			append(p, t1);
			if (if_block2) if_block2.m(p, null);
			append(p, t2);
			if (if_block3) if_block3.m(p, null);
			append(p, t3);
			if (if_block4) if_block4.m(p, null);
		},
		p(ctx, dirty) {
			if (/*interior*/ ctx[0].kook) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_7(ctx);
					if_block0.c();
					if_block0.m(p, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*interior*/ ctx[0].jahutus) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_6(ctx);
					if_block1.c();
					if_block1.m(p, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*interior*/ ctx[0].pladipakett) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_5(ctx);
					if_block2.c();
					if_block2.m(p, t2);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*interior*/ ctx[0].vann) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block_4(ctx);
					if_block3.c();
					if_block3.m(p, t3);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (/*interior*/ ctx[0].leiliruum) {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_3(ctx);
					if_block4.c();
					if_block4.m(p, null);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}
		},
		d(detaching) {
			if (detaching) detach(p);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
		}
	};
}

// (409:4) {#if interior.kook}
function create_if_block_7(ctx) {
	let t_value = /*interior*/ ctx[0].kook + "";
	let t;
	let br;

	return {
		c() {
			t = text(t_value);
			br = element("br");
		},
		m(target, anchor) {
			insert(target, t, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].kook + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(br);
		}
	};
}

// (412:4) {#if interior.jahutus}
function create_if_block_6(ctx) {
	let t_value = /*interior*/ ctx[0].jahutus + "";
	let t;
	let br;

	return {
		c() {
			t = text(t_value);
			br = element("br");
		},
		m(target, anchor) {
			insert(target, t, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].jahutus + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(br);
		}
	};
}

// (415:4) {#if interior.pladipakett}
function create_if_block_5(ctx) {
	let t_value = /*interior*/ ctx[0].pladipakett + "";
	let t;
	let br;

	return {
		c() {
			t = text(t_value);
			br = element("br");
		},
		m(target, anchor) {
			insert(target, t, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].pladipakett + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(br);
		}
	};
}

// (418:4) {#if interior.vann}
function create_if_block_4(ctx) {
	let t_value = /*interior*/ ctx[0].vann + "";
	let t;
	let br;

	return {
		c() {
			t = text(t_value);
			br = element("br");
		},
		m(target, anchor) {
			insert(target, t, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].vann + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(br);
		}
	};
}

// (421:4) {#if interior.leiliruum}
function create_if_block_3(ctx) {
	let t_value = /*interior*/ ctx[0].leiliruum + "";
	let t;
	let br;

	return {
		c() {
			t = text(t_value);
			br = element("br");
		},
		m(target, anchor) {
			insert(target, t, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].leiliruum + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(br);
		}
	};
}

// (481:4) {:else}
function create_else_block(ctx) {
	let div;
	let textarea;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			textarea = element("textarea");
			attr(textarea, "class", "textarea");
			attr(textarea, "placeholder", "");
			attr(textarea, "rows", "5");
			attr(div, "class", "control");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, textarea);
			set_input_value(textarea, /*interior*/ ctx[0].comments);

			if (!mounted) {
				dispose = listen(textarea, "input", /*textarea_input_handler_1*/ ctx[16]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1) {
				set_input_value(textarea, /*interior*/ ctx[0].comments);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (477:4) {#if interior.is_interior_inactive}
function create_if_block_1(ctx) {
	let p;
	let t_value = /*interior*/ ctx[0].comments + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*interior*/ 1 && t_value !== (t_value = /*interior*/ ctx[0].comments + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (488:4) {#if !interior.is_interior_inactive}
function create_if_block(ctx) {
	let div;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			button.textContent = "Salvesta";
			attr(button, "class", "button is-primary");
			attr(div, "class", "control");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", /*handleSubmit*/ ctx[2]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let h2;
	let t0;
	let t1_value = /*interior*/ ctx[0].addr + "";
	let t1;
	let t2;
	let hr0;
	let t3;
	let div4;
	let div0;
	let article0;
	let h30;
	let t5;
	let t6;
	let div2;
	let article1;
	let div1;
	let p0;
	let lightbox0;
	let t7;
	let p1;
	let lightbox1;
	let t8;
	let div3;
	let t9;
	let hr1;
	let t10;
	let div9;
	let div5;
	let article3;
	let h31;
	let t12;
	let t13;
	let div7;
	let article4;
	let div6;
	let p2;
	let lightbox2;
	let t14;
	let div8;
	let t15;
	let hr2;
	let t16;
	let div14;
	let div10;
	let article6;
	let h32;
	let t18;
	let t19;
	let div12;
	let article7;
	let div11;
	let p3;
	let lightbox3;
	let t20;
	let div13;
	let t21;
	let hr3;
	let t22;
	let div19;
	let div15;
	let article9;
	let h33;
	let t24;
	let t25;
	let div17;
	let article10;
	let div16;
	let p4;
	let lightbox4;
	let t26;
	let div18;
	let t27;
	let hr4;
	let t28;
	let div23;
	let div21;
	let article12;
	let h34;
	let t30;
	let div20;
	let label;
	let t32;
	let t33;
	let t34;
	let t35;
	let div22;
	let t36;
	let hr5;
	let t37;
	let div26;
	let div24;
	let article14;
	let h35;
	let t39;
	let t40;
	let div25;
	let t41;
	let hr6;
	let t42;
	let div30;
	let div29;
	let article16;
	let h36;
	let t44;
	let div27;
	let t45;
	let div28;
	let current;

	function select_block_type(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_15;
		return create_else_block_7;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);

	lightbox0 = new Lightbox({
			props: {
				thumbnail: true,
				description: "Lightbox with thumbnail and image",
				$$slots: {
					image: [create_image_slot_4],
					thumbnail: [create_thumbnail_slot_4]
				},
				$$scope: { ctx }
			}
		});

	lightbox1 = new Lightbox({
			props: {
				thumbnail: true,
				description: "Lightbox with thumbnail and image",
				$$slots: {
					image: [create_image_slot_3],
					thumbnail: [create_thumbnail_slot_3]
				},
				$$scope: { ctx }
			}
		});

	function select_block_type_1(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_14;
		return create_else_block_6;
	}

	let current_block_type_1 = select_block_type_1(ctx, -1);
	let if_block1 = current_block_type_1(ctx);

	lightbox2 = new Lightbox({
			props: {
				thumbnail: true,
				description: "Lightbox with thumbnail and image",
				$$slots: {
					image: [create_image_slot_2],
					thumbnail: [create_thumbnail_slot_2]
				},
				$$scope: { ctx }
			}
		});

	function select_block_type_2(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_13;
		return create_else_block_5;
	}

	let current_block_type_2 = select_block_type_2(ctx, -1);
	let if_block2 = current_block_type_2(ctx);

	lightbox3 = new Lightbox({
			props: {
				thumbnail: true,
				description: "Lightbox with thumbnail and image",
				$$slots: {
					image: [create_image_slot_1],
					thumbnail: [create_thumbnail_slot_1]
				},
				$$scope: { ctx }
			}
		});

	function select_block_type_3(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_12;
		return create_else_block_4;
	}

	let current_block_type_3 = select_block_type_3(ctx, -1);
	let if_block3 = current_block_type_3(ctx);

	lightbox4 = new Lightbox({
			props: {
				thumbnail: true,
				description: "Lightbox with thumbnail and image",
				$$slots: {
					image: [create_image_slot],
					thumbnail: [create_thumbnail_slot]
				},
				$$scope: { ctx }
			}
		});

	function select_block_type_4(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_11;
		return create_else_block_3;
	}

	let current_block_type_4 = select_block_type_4(ctx, -1);
	let if_block4 = current_block_type_4(ctx);
	let if_block5 = !/*interior*/ ctx[0].is_interior_inactive && create_if_block_10(ctx);
	let if_block6 = /*interior*/ ctx[0].seinavarv_is_uncommon && create_if_block_8(ctx);

	function select_block_type_6(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_2;
		return create_else_block_1;
	}

	let current_block_type_5 = select_block_type_6(ctx, -1);
	let if_block7 = current_block_type_5(ctx);

	function select_block_type_7(ctx, dirty) {
		if (/*interior*/ ctx[0].is_interior_inactive) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type_6 = select_block_type_7(ctx, -1);
	let if_block8 = current_block_type_6(ctx);
	let if_block9 = !/*interior*/ ctx[0].is_interior_inactive && create_if_block(ctx);

	return {
		c() {
			h2 = element("h2");
			t0 = text("Siseviimistluse valik - ");
			t1 = text(t1_value);
			t2 = space();
			hr0 = element("hr");
			t3 = space();
			div4 = element("div");
			div0 = element("div");
			article0 = element("article");
			h30 = element("h3");
			h30.textContent = "Plaadid";
			t5 = space();
			if_block0.c();
			t6 = space();
			div2 = element("div");
			article1 = element("article");
			div1 = element("div");
			p0 = element("p");
			create_component(lightbox0.$$.fragment);
			t7 = space();
			p1 = element("p");
			create_component(lightbox1.$$.fragment);
			t8 = space();
			div3 = element("div");
			div3.innerHTML = `<article class="tile is-child"></article>`;
			t9 = space();
			hr1 = element("hr");
			t10 = space();
			div9 = element("div");
			div5 = element("div");
			article3 = element("article");
			h31 = element("h3");
			h31.textContent = "Santehnika";
			t12 = space();
			if_block1.c();
			t13 = space();
			div7 = element("div");
			article4 = element("article");
			div6 = element("div");
			p2 = element("p");
			create_component(lightbox2.$$.fragment);
			t14 = space();
			div8 = element("div");
			div8.innerHTML = `<article class="tile is-child"></article>`;
			t15 = space();
			hr2 = element("hr");
			t16 = space();
			div14 = element("div");
			div10 = element("div");
			article6 = element("article");
			h32 = element("h3");
			h32.textContent = "Põrandaliist";
			t18 = space();
			if_block2.c();
			t19 = space();
			div12 = element("div");
			article7 = element("article");
			div11 = element("div");
			p3 = element("p");
			create_component(lightbox3.$$.fragment);
			t20 = space();
			div13 = element("div");
			div13.innerHTML = `<article class="tile is-child"></article>`;
			t21 = space();
			hr3 = element("hr");
			t22 = space();
			div19 = element("div");
			div15 = element("div");
			article9 = element("article");
			h33 = element("h3");
			h33.textContent = "Siseuks";
			t24 = space();
			if_block3.c();
			t25 = space();
			div17 = element("div");
			article10 = element("article");
			div16 = element("div");
			p4 = element("p");
			create_component(lightbox4.$$.fragment);
			t26 = space();
			div18 = element("div");
			div18.innerHTML = `<article class="tile is-child"></article>`;
			t27 = space();
			hr4 = element("hr");
			t28 = space();
			div23 = element("div");
			div21 = element("div");
			article12 = element("article");
			h34 = element("h3");
			h34.textContent = "Seinavärv";
			t30 = space();
			div20 = element("div");
			label = element("label");
			label.textContent = "Ühtlane värv igas toas";
			t32 = space();
			if_block4.c();
			t33 = space();
			if (if_block5) if_block5.c();
			t34 = space();
			if (if_block6) if_block6.c();
			t35 = space();
			div22 = element("div");
			div22.innerHTML = `<article class="tile is-child"></article>`;
			t36 = space();
			hr5 = element("hr");
			t37 = space();
			div26 = element("div");
			div24 = element("div");
			article14 = element("article");
			h35 = element("h3");
			h35.textContent = "Lisatasu eest";
			t39 = space();
			if_block7.c();
			t40 = space();
			div25 = element("div");
			div25.innerHTML = `<article class="tile is-child"></article>`;
			t41 = space();
			hr6 = element("hr");
			t42 = space();
			div30 = element("div");
			div29 = element("div");
			article16 = element("article");
			h36 = element("h3");
			h36.textContent = "Kommentaarid";
			t44 = space();
			div27 = element("div");
			if_block8.c();
			t45 = space();
			div28 = element("div");
			if (if_block9) if_block9.c();
			attr(h2, "class", "title");
			attr(h30, "class", "subtitle");
			attr(article0, "class", "tile is-child");
			attr(div0, "class", "tile is-parent");
			attr(p0, "class", "control");
			attr(p1, "class", "control");
			attr(div1, "class", "field is-grouped");
			attr(article1, "class", "tile is-child");
			attr(div2, "class", "tile is-parent");
			attr(div3, "class", "tile is-parent");
			attr(div4, "class", "tile is-ancestor");
			attr(h31, "class", "subtitle");
			attr(article3, "class", "tile is-child");
			attr(div5, "class", "tile is-parent");
			attr(p2, "class", "control");
			attr(div6, "class", "field is-grouped");
			attr(article4, "class", "tile is-child");
			attr(div7, "class", "tile is-parent");
			attr(div8, "class", "tile is-parent");
			attr(div9, "class", "tile is-ancestor");
			attr(h32, "class", "subtitle");
			attr(article6, "class", "tile is-child");
			attr(div10, "class", "tile is-parent");
			attr(p3, "class", "control");
			attr(div11, "class", "field is-grouped");
			attr(article7, "class", "tile is-child");
			attr(div12, "class", "tile is-parent");
			attr(div13, "class", "tile is-parent");
			attr(div14, "class", "tile is-ancestor");
			attr(h33, "class", "subtitle");
			attr(article9, "class", "tile is-child");
			attr(div15, "class", "tile is-parent");
			attr(p4, "class", "control");
			attr(div16, "class", "field is-grouped");
			attr(article10, "class", "tile is-child");
			attr(div17, "class", "tile is-parent");
			attr(div18, "class", "tile is-parent");
			attr(div19, "class", "tile is-ancestor");
			attr(h34, "class", "subtitle");
			attr(label, "class", "label");
			attr(div20, "class", "field");
			attr(article12, "class", "tile is-child");
			attr(div21, "class", "tile is-parent");
			attr(div22, "class", "tile is-parent");
			attr(div23, "class", "tile is-ancestor");
			attr(h35, "class", "subtitle");
			attr(article14, "class", "tile is-child");
			attr(div24, "class", "tile is-parent");
			attr(div25, "class", "tile is-parent");
			attr(div26, "class", "tile is-ancestor");
			attr(h36, "class", "subtitle");
			attr(div27, "class", "field");
			attr(div28, "class", "field is-grouped");
			attr(article16, "class", "tile is-child");
			attr(div29, "class", "tile is-parent");
			attr(div30, "class", "tile is-ancestor");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			append(h2, t0);
			append(h2, t1);
			insert(target, t2, anchor);
			insert(target, hr0, anchor);
			insert(target, t3, anchor);
			insert(target, div4, anchor);
			append(div4, div0);
			append(div0, article0);
			append(article0, h30);
			append(article0, t5);
			if_block0.m(article0, null);
			append(div4, t6);
			append(div4, div2);
			append(div2, article1);
			append(article1, div1);
			append(div1, p0);
			mount_component(lightbox0, p0, null);
			append(div1, t7);
			append(div1, p1);
			mount_component(lightbox1, p1, null);
			append(div4, t8);
			append(div4, div3);
			insert(target, t9, anchor);
			insert(target, hr1, anchor);
			insert(target, t10, anchor);
			insert(target, div9, anchor);
			append(div9, div5);
			append(div5, article3);
			append(article3, h31);
			append(article3, t12);
			if_block1.m(article3, null);
			append(div9, t13);
			append(div9, div7);
			append(div7, article4);
			append(article4, div6);
			append(div6, p2);
			mount_component(lightbox2, p2, null);
			append(div9, t14);
			append(div9, div8);
			insert(target, t15, anchor);
			insert(target, hr2, anchor);
			insert(target, t16, anchor);
			insert(target, div14, anchor);
			append(div14, div10);
			append(div10, article6);
			append(article6, h32);
			append(article6, t18);
			if_block2.m(article6, null);
			append(div14, t19);
			append(div14, div12);
			append(div12, article7);
			append(article7, div11);
			append(div11, p3);
			mount_component(lightbox3, p3, null);
			append(div14, t20);
			append(div14, div13);
			insert(target, t21, anchor);
			insert(target, hr3, anchor);
			insert(target, t22, anchor);
			insert(target, div19, anchor);
			append(div19, div15);
			append(div15, article9);
			append(article9, h33);
			append(article9, t24);
			if_block3.m(article9, null);
			append(div19, t25);
			append(div19, div17);
			append(div17, article10);
			append(article10, div16);
			append(div16, p4);
			mount_component(lightbox4, p4, null);
			append(div19, t26);
			append(div19, div18);
			insert(target, t27, anchor);
			insert(target, hr4, anchor);
			insert(target, t28, anchor);
			insert(target, div23, anchor);
			append(div23, div21);
			append(div21, article12);
			append(article12, h34);
			append(article12, t30);
			append(article12, div20);
			append(div20, label);
			append(div20, t32);
			if_block4.m(div20, null);
			append(article12, t33);
			if (if_block5) if_block5.m(article12, null);
			append(article12, t34);
			if (if_block6) if_block6.m(article12, null);
			append(div23, t35);
			append(div23, div22);
			insert(target, t36, anchor);
			insert(target, hr5, anchor);
			insert(target, t37, anchor);
			insert(target, div26, anchor);
			append(div26, div24);
			append(div24, article14);
			append(article14, h35);
			append(article14, t39);
			if_block7.m(article14, null);
			append(div26, t40);
			append(div26, div25);
			insert(target, t41, anchor);
			insert(target, hr6, anchor);
			insert(target, t42, anchor);
			insert(target, div30, anchor);
			append(div30, div29);
			append(div29, article16);
			append(article16, h36);
			append(article16, t44);
			append(article16, div27);
			if_block8.m(div27, null);
			append(article16, t45);
			append(article16, div28);
			if (if_block9) if_block9.m(div28, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*interior*/ 1) && t1_value !== (t1_value = /*interior*/ ctx[0].addr + "")) set_data(t1, t1_value);

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(article0, null);
				}
			}

			const lightbox0_changes = {};

			if (dirty & /*$$scope*/ 33554432) {
				lightbox0_changes.$$scope = { dirty, ctx };
			}

			lightbox0.$set(lightbox0_changes);
			const lightbox1_changes = {};

			if (dirty & /*$$scope*/ 33554432) {
				lightbox1_changes.$$scope = { dirty, ctx };
			}

			lightbox1.$set(lightbox1_changes);

			if (current_block_type_1 === (current_block_type_1 = select_block_type_1(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if_block1.d(1);
				if_block1 = current_block_type_1(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(article3, null);
				}
			}

			const lightbox2_changes = {};

			if (dirty & /*$$scope*/ 33554432) {
				lightbox2_changes.$$scope = { dirty, ctx };
			}

			lightbox2.$set(lightbox2_changes);

			if (current_block_type_2 === (current_block_type_2 = select_block_type_2(ctx, dirty)) && if_block2) {
				if_block2.p(ctx, dirty);
			} else {
				if_block2.d(1);
				if_block2 = current_block_type_2(ctx);

				if (if_block2) {
					if_block2.c();
					if_block2.m(article6, null);
				}
			}

			const lightbox3_changes = {};

			if (dirty & /*$$scope*/ 33554432) {
				lightbox3_changes.$$scope = { dirty, ctx };
			}

			lightbox3.$set(lightbox3_changes);

			if (current_block_type_3 === (current_block_type_3 = select_block_type_3(ctx, dirty)) && if_block3) {
				if_block3.p(ctx, dirty);
			} else {
				if_block3.d(1);
				if_block3 = current_block_type_3(ctx);

				if (if_block3) {
					if_block3.c();
					if_block3.m(article9, null);
				}
			}

			const lightbox4_changes = {};

			if (dirty & /*$$scope*/ 33554432) {
				lightbox4_changes.$$scope = { dirty, ctx };
			}

			lightbox4.$set(lightbox4_changes);

			if (current_block_type_4 === (current_block_type_4 = select_block_type_4(ctx, dirty)) && if_block4) {
				if_block4.p(ctx, dirty);
			} else {
				if_block4.d(1);
				if_block4 = current_block_type_4(ctx);

				if (if_block4) {
					if_block4.c();
					if_block4.m(div20, null);
				}
			}

			if (!/*interior*/ ctx[0].is_interior_inactive) {
				if (if_block5) {
					if_block5.p(ctx, dirty);
				} else {
					if_block5 = create_if_block_10(ctx);
					if_block5.c();
					if_block5.m(article12, t34);
				}
			} else if (if_block5) {
				if_block5.d(1);
				if_block5 = null;
			}

			if (/*interior*/ ctx[0].seinavarv_is_uncommon) {
				if (if_block6) {
					if_block6.p(ctx, dirty);
				} else {
					if_block6 = create_if_block_8(ctx);
					if_block6.c();
					if_block6.m(article12, null);
				}
			} else if (if_block6) {
				if_block6.d(1);
				if_block6 = null;
			}

			if (current_block_type_5 === (current_block_type_5 = select_block_type_6(ctx, dirty)) && if_block7) {
				if_block7.p(ctx, dirty);
			} else {
				if_block7.d(1);
				if_block7 = current_block_type_5(ctx);

				if (if_block7) {
					if_block7.c();
					if_block7.m(article14, null);
				}
			}

			if (current_block_type_6 === (current_block_type_6 = select_block_type_7(ctx, dirty)) && if_block8) {
				if_block8.p(ctx, dirty);
			} else {
				if_block8.d(1);
				if_block8 = current_block_type_6(ctx);

				if (if_block8) {
					if_block8.c();
					if_block8.m(div27, null);
				}
			}

			if (!/*interior*/ ctx[0].is_interior_inactive) {
				if (if_block9) {
					if_block9.p(ctx, dirty);
				} else {
					if_block9 = create_if_block(ctx);
					if_block9.c();
					if_block9.m(div28, null);
				}
			} else if (if_block9) {
				if_block9.d(1);
				if_block9 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(lightbox0.$$.fragment, local);
			transition_in(lightbox1.$$.fragment, local);
			transition_in(lightbox2.$$.fragment, local);
			transition_in(lightbox3.$$.fragment, local);
			transition_in(lightbox4.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(lightbox0.$$.fragment, local);
			transition_out(lightbox1.$$.fragment, local);
			transition_out(lightbox2.$$.fragment, local);
			transition_out(lightbox3.$$.fragment, local);
			transition_out(lightbox4.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t2);
			if (detaching) detach(hr0);
			if (detaching) detach(t3);
			if (detaching) detach(div4);
			if_block0.d();
			destroy_component(lightbox0);
			destroy_component(lightbox1);
			if (detaching) detach(t9);
			if (detaching) detach(hr1);
			if (detaching) detach(t10);
			if (detaching) detach(div9);
			if_block1.d();
			destroy_component(lightbox2);
			if (detaching) detach(t15);
			if (detaching) detach(hr2);
			if (detaching) detach(t16);
			if (detaching) detach(div14);
			if_block2.d();
			destroy_component(lightbox3);
			if (detaching) detach(t21);
			if (detaching) detach(hr3);
			if (detaching) detach(t22);
			if (detaching) detach(div19);
			if_block3.d();
			destroy_component(lightbox4);
			if (detaching) detach(t27);
			if (detaching) detach(hr4);
			if (detaching) detach(t28);
			if (detaching) detach(div23);
			if_block4.d();
			if (if_block5) if_block5.d();
			if (if_block6) if_block6.d();
			if (detaching) detach(t36);
			if (detaching) detach(hr5);
			if (detaching) detach(t37);
			if (detaching) detach(div26);
			if_block7.d();
			if (detaching) detach(t41);
			if (detaching) detach(hr6);
			if (detaching) detach(t42);
			if (detaching) detach(div30);
			if_block8.d();
			if (if_block9) if_block9.d();
		}
	};
}

let api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcF9pbnRlcmlvckB3aW5nbWFjaHQuZWUiLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6OCwicm9sZXMiOiJ1c2VyIiwiaWF0IjoxNjQ4MDI2NjY2fQ.o8thq4oXWdrlVaOzEBtp9wlfrlySwwX0ecXVXA-gllY';
let entity = 'interior';

function instance($$self, $$props, $$invalidate) {
	let $location;
	let $user_address;
	let $user;
	component_subscribe($$self, user_address, $$value => $$invalidate(18, $user_address = $$value));
	component_subscribe($$self, user, $$value => $$invalidate(19, $user = $$value));
	const navigate = useNavigate();
	const location = useLocation();
	component_subscribe($$self, location, value => $$invalidate(17, $location = value));
	const params = useParams();
	let interior = {};
	let interior_link = $user;
	let url_get = 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/' + entity + '/?limit=1&sort=-id&where=' + '(link,eq,' + interior_link + ')';
	let url_insert = 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/' + entity;

	onMount(async () => {
		fetch(url_get, { headers: { 'xc-auth': api_key } }).then(response => response.json()).then(data => {
			$$invalidate(0, interior = data[0]);
			set_store_value(user_address, $user_address = interior.addr, $user_address);
		});
	});

	function handleSubmit() {
		delete interior.Id;

		fetch(url_insert, {
			method: 'POST',
			headers: {
				'xc-auth': api_key,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(interior)
		}).then(response => response.json()).then(data => {
			//alert(JSON.stringify(data))	
			navigate("/thankyou", {
				state: { from: $location.pathname },
				replace: true
			});
		});
	}

	function onChange_plaadid(event) {
		$$invalidate(0, interior.plaadid = event.currentTarget.value, interior);
	}

	function onChange_santehnika(event) {
		$$invalidate(0, interior.santehnika = event.currentTarget.value, interior);
	}

	function onChange_liist(event) {
		$$invalidate(0, interior.liist = event.currentTarget.value, interior);
	}

	function onChange_kook(event) {
		$$invalidate(0, interior.kook = '', interior);

		if (event.srcElement.checked) {
			$$invalidate(0, interior.kook = event.currentTarget.value, interior);
		}
	}

	function onChange_jahutus(event) {
		$$invalidate(0, interior.jahutus = '', interior);

		if (event.srcElement.checked) {
			$$invalidate(0, interior.jahutus = event.currentTarget.value, interior);
		}
	}

	function onChange_plaadipakett(event) {
		$$invalidate(0, interior.plaadipakett = '', interior);

		if (event.srcElement.checked) {
			$$invalidate(0, interior.plaadipakett = event.currentTarget.value, interior);
		}
	}

	function onChange_vann(event) {
		$$invalidate(0, interior.vann = '', interior);

		if (event.srcElement.checked) {
			$$invalidate(0, interior.vann = event.currentTarget.value, interior);
		}
	}

	function onChange_leiliruum(event) {
		$$invalidate(0, interior.leiliruum = '', interior);

		if (event.srcElement.checked) {
			$$invalidate(0, interior.leiliruum = event.currentTarget.value, interior);
		}
	}

	function onChange_siseuks(event) {
		$$invalidate(0, interior.siseuks = event.currentTarget.value, interior);
	}

	if (interior.lisatasu) {
		
	} else {
		interior.lisatasu = [];
	}

	if (interior.seinavarv) {
		
	} else {
		interior.seinavarv = 'Valge';
	}

	function handle_Chg_seinavarv_is_uncommon(event) {
		if (interior.seinavarv_is_uncommon) {
			if (interior.seinavarv_details == "") {
				$$invalidate(
					0,
					interior.seinavarv_details = `Elutuba - Valge
Magamistuba - Valge
Tuba1 - Valge
Tuba2 - Valge
Koridor - Valge
WC - Valge`,
					interior
				);
			}
		}
	}

	function input_input_handler() {
		interior.seinavarv = this.value;
		$$invalidate(0, interior);
	}

	function input_change_handler() {
		interior.seinavarv_is_uncommon = this.checked;
		$$invalidate(0, interior);
	}

	function textarea_input_handler() {
		interior.seinavarv_details = this.value;
		$$invalidate(0, interior);
	}

	function textarea_input_handler_1() {
		interior.comments = this.value;
		$$invalidate(0, interior);
	}

	return [
		interior,
		location,
		handleSubmit,
		onChange_plaadid,
		onChange_santehnika,
		onChange_liist,
		onChange_kook,
		onChange_jahutus,
		onChange_plaadipakett,
		onChange_vann,
		onChange_leiliruum,
		onChange_siseuks,
		handle_Chg_seinavarv_is_uncommon,
		input_input_handler,
		input_change_handler,
		textarea_input_handler,
		textarea_input_handler_1
	];
}

class Demo extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Demo;