/* Default.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "./_snowpack/pkg/svelte/internal.js";

import { Router, Route, Link } from "./_snowpack/pkg/svelte-navigator.js";
import { user } from "./stores.js";
import { useNavigate } from "./_snowpack/pkg/svelte-navigator.js";

function create_if_block(ctx) {
	let div9;
	let div3;
	let div2;
	let div1;
	let div0;
	let link0;
	let t0;
	let div7;
	let div6;
	let div5;
	let div4;
	let link1;
	let t1;
	let div8;
	let current;

	link0 = new Link({
			props: {
				to: "demo",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	link1 = new Link({
			props: {
				to: "garantii",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div9 = element("div");
			div3 = element("div");
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");
			create_component(link0.$$.fragment);
			t0 = space();
			div7 = element("div");
			div6 = element("div");
			div5 = element("div");
			div4 = element("div");
			create_component(link1.$$.fragment);
			t1 = space();
			div8 = element("div");
			attr(div0, "class", "content");
			attr(div1, "class", "card-content");
			attr(div2, "class", "card");
			attr(div3, "class", "column");
			attr(div4, "class", "content");
			attr(div5, "class", "card-content");
			attr(div6, "class", "card");
			attr(div7, "class", "column");
			attr(div8, "class", "column");
			attr(div9, "class", "columns");
		},
		m(target, anchor) {
			insert(target, div9, anchor);
			append(div9, div3);
			append(div3, div2);
			append(div2, div1);
			append(div1, div0);
			mount_component(link0, div0, null);
			append(div9, t0);
			append(div9, div7);
			append(div7, div6);
			append(div6, div5);
			append(div5, div4);
			mount_component(link1, div4, null);
			append(div9, t1);
			append(div9, div8);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(link0.$$.fragment, local);
			transition_in(link1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link0.$$.fragment, local);
			transition_out(link1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div9);
			destroy_component(link0);
			destroy_component(link1);
		}
	};
}

// (28:5) <Link to="demo">
function create_default_slot_1(ctx) {
	let span3;

	return {
		c() {
			span3 = element("span");

			span3.innerHTML = `<span class="icon"><span class="mdi mdi-home-edit-outline"></span></span> 
					  <span>Siseviimistluse valik</span>`;

			attr(span3, "class", "icon-text");
		},
		m(target, anchor) {
			insert(target, span3, anchor);
		},
		d(detaching) {
			if (detaching) detach(span3);
		}
	};
}

// (46:6) <Link to="garantii">
function create_default_slot(ctx) {
	let span3;

	return {
		c() {
			span3 = element("span");

			span3.innerHTML = `<span class="icon"><span class="mdi mdi-account-question-outline"></span></span> 
					  <span>Garantii</span>`;

			attr(span3, "class", "icon-text");
		},
		m(target, anchor) {
			insert(target, span3, anchor);
		},
		d(detaching) {
			if (detaching) detach(span3);
		}
	};
}

function create_fragment(ctx) {
	let h2;
	let t1;
	let hr;
	let t2;
	let if_block_anchor;
	let current;
	let if_block = /*$user*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Valikud:";
			t1 = space();
			hr = element("hr");
			t2 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(h2, "class", "title");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, hr, anchor);
			insert(target, t2, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$user*/ ctx[0]) {
				if (if_block) {
					if (dirty & /*$user*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(hr);
			if (detaching) detach(t2);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $user;
	component_subscribe($$self, user, $$value => $$invalidate(0, $user = $$value));
	const navigate = useNavigate();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let dest = urlParams.get('dest');
	let id = urlParams.get('id');

	if (dest == 'task') {
		navigate("task/" + id);
	}

	return [$user];
}

class Default extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Default;