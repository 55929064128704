/* Task.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text
} from "./_snowpack/pkg/svelte/internal.js";

import { onMount } from './_snowpack/pkg/svelte.js';
import { api_get, api_put } from "./api.js";
import { useParams, useNavigate, useLocation } from "./_snowpack/pkg/svelte-navigator.js";
import { user } from "./stores.js";
import { is_DEV } from "./stores.js";
import { sendEmail, gen_link_worker } from './funcs.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	return child_ctx;
}

// (58:0) {#if errors.length}
function create_if_block_1(ctx) {
	let article;
	let div;
	let ul;
	let each_value = /*errors*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			article = element("article");
			div = element("div");
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "message-body");
			attr(article, "class", "message is-danger");
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div);
			append(div, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*errors*/ 2) {
				each_value = /*errors*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(article);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (62:12) {#each errors as a}
function create_each_block(ctx) {
	let li;
	let t_value = /*a*/ ctx[11] + "";
	let t;

	return {
		c() {
			li = element("li");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, t);
		},
		p(ctx, dirty) {
			if (dirty & /*errors*/ 2 && t_value !== (t_value = /*a*/ ctx[11] + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (70:0) {#if task}
function create_if_block(ctx) {
	let h2;
	let t1;
	let p;
	let t2_value = /*task*/ ctx[0].garantii_comment + "";
	let t2;
	let t3;
	let div1;
	let div0;
	let label0;
	let input0;
	let t4;
	let t5_value = /*task*/ ctx[0].date1 + "";
	let t5;
	let t6;
	let t7_value = /*task*/ ctx[0].time1 + "";
	let t7;
	let t8;
	let div3;
	let div2;
	let label1;
	let input1;
	let t9;
	let t10_value = /*task*/ ctx[0].date2 + "";
	let t10;
	let t11;
	let t12_value = /*task*/ ctx[0].time2 + "";
	let t12;
	let t13;
	let div5;
	let div4;
	let label2;
	let input2;
	let t14;
	let t15_value = /*task*/ ctx[0].date3 + "";
	let t15;
	let t16;
	let t17_value = /*task*/ ctx[0].time3 + "";
	let t17;
	let t18;
	let div6;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Aja kinnitamine";
			t1 = space();
			p = element("p");
			t2 = text(t2_value);
			t3 = space();
			div1 = element("div");
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t4 = text("\r\n            1. ");
			t5 = text(t5_value);
			t6 = space();
			t7 = text(t7_value);
			t8 = space();
			div3 = element("div");
			div2 = element("div");
			label1 = element("label");
			input1 = element("input");
			t9 = text("\r\n            2. ");
			t10 = text(t10_value);
			t11 = space();
			t12 = text(t12_value);
			t13 = space();
			div5 = element("div");
			div4 = element("div");
			label2 = element("label");
			input2 = element("input");
			t14 = text("\r\n            3. ");
			t15 = text(t15_value);
			t16 = space();
			t17 = text(t17_value);
			t18 = space();
			div6 = element("div");
			button = element("button");
			button.textContent = "Kinnita aeg ja Salvesta";
			attr(h2, "class", "title");
			attr(input0, "type", "radio");
			attr(input0, "name", "choice");
			input0.value = "1";
			attr(div0, "class", "control");
			attr(div1, "class", "field");
			attr(input1, "type", "radio");
			attr(input1, "name", "choice");
			input1.value = "2";
			attr(div2, "class", "control");
			attr(div3, "class", "field");
			attr(input2, "type", "radio");
			attr(input2, "name", "choice");
			input2.value = "3";
			attr(div4, "class", "control");
			attr(div5, "class", "field");
			attr(button, "class", "button is-primary");
			attr(div6, "class", "field");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, p, anchor);
			append(p, t2);
			insert(target, t3, anchor);
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, label0);
			append(label0, input0);
			append(label0, t4);
			append(label0, t5);
			append(label0, t6);
			append(label0, t7);
			insert(target, t8, anchor);
			insert(target, div3, anchor);
			append(div3, div2);
			append(div2, label1);
			append(label1, input1);
			append(label1, t9);
			append(label1, t10);
			append(label1, t11);
			append(label1, t12);
			insert(target, t13, anchor);
			insert(target, div5, anchor);
			append(div5, div4);
			append(div4, label2);
			append(label2, input2);
			append(label2, t14);
			append(label2, t15);
			append(label2, t16);
			append(label2, t17);
			insert(target, t18, anchor);
			insert(target, div6, anchor);
			append(div6, button);

			if (!mounted) {
				dispose = [
					listen(input0, "change", function () {
						if (is_function(/*handle_change*/ ctx[5](/*task*/ ctx[0].date1, /*task*/ ctx[0].time1))) /*handle_change*/ ctx[5](/*task*/ ctx[0].date1, /*task*/ ctx[0].time1).apply(this, arguments);
					}),
					listen(input1, "change", function () {
						if (is_function(/*handle_change*/ ctx[5](/*task*/ ctx[0].date2, /*task*/ ctx[0].time2))) /*handle_change*/ ctx[5](/*task*/ ctx[0].date2, /*task*/ ctx[0].time2).apply(this, arguments);
					}),
					listen(input2, "change", function () {
						if (is_function(/*handle_change*/ ctx[5](/*task*/ ctx[0].date3, /*task*/ ctx[0].time3))) /*handle_change*/ ctx[5](/*task*/ ctx[0].date3, /*task*/ ctx[0].time3).apply(this, arguments);
					}),
					listen(button, "click", /*handle_submit*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*task*/ 1 && t2_value !== (t2_value = /*task*/ ctx[0].garantii_comment + "")) set_data(t2, t2_value);
			if (dirty & /*task*/ 1 && t5_value !== (t5_value = /*task*/ ctx[0].date1 + "")) set_data(t5, t5_value);
			if (dirty & /*task*/ 1 && t7_value !== (t7_value = /*task*/ ctx[0].time1 + "")) set_data(t7, t7_value);
			if (dirty & /*task*/ 1 && t10_value !== (t10_value = /*task*/ ctx[0].date2 + "")) set_data(t10, t10_value);
			if (dirty & /*task*/ 1 && t12_value !== (t12_value = /*task*/ ctx[0].time2 + "")) set_data(t12, t12_value);
			if (dirty & /*task*/ 1 && t15_value !== (t15_value = /*task*/ ctx[0].date3 + "")) set_data(t15, t15_value);
			if (dirty & /*task*/ 1 && t17_value !== (t17_value = /*task*/ ctx[0].time3 + "")) set_data(t17, t17_value);
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(p);
			if (detaching) detach(t3);
			if (detaching) detach(div1);
			if (detaching) detach(t8);
			if (detaching) detach(div3);
			if (detaching) detach(t13);
			if (detaching) detach(div5);
			if (detaching) detach(t18);
			if (detaching) detach(div6);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let t;
	let if_block1_anchor;
	let if_block0 = /*errors*/ ctx[1].length && create_if_block_1(ctx);
	let if_block1 = /*task*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*errors*/ ctx[1].length) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*task*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $location;
	let $is_DEV;
	let $user;
	let $params;
	component_subscribe($$self, is_DEV, $$value => $$invalidate(7, $is_DEV = $$value));
	component_subscribe($$self, user, $$value => $$invalidate(8, $user = $$value));
	const navigate = useNavigate();
	const location = useLocation();
	component_subscribe($$self, location, value => $$invalidate(6, $location = value));
	const params = useParams();
	component_subscribe($$self, params, value => $$invalidate(9, $params = value));
	let task = null;

	onMount(async () => {
		api_get("task/" + $params.id).then(response => response.json()).then(data => {
			$$invalidate(0, task = data);
			$$invalidate(0, task.date_confirmed = "", task);
			$$invalidate(0, task.time_confirmed = "", task);
		});
	});

	let errors = [];

	function handle_submit() {
		let body = {
			"date_confirmed": task.date_confirmed,
			"time_confirmed": task.time_confirmed,
			"color": "#73df28"
		};

		$$invalidate(1, errors = []);

		if ((/^.+$/).test(body.date_confirmed)) {
			
		} else {
			errors.push("Kuupäev peaks olema valitud");
		}

		if ((/^.+$/).test(body.time_confirmed)) {
			
		} else {
			errors.push("Ajavahemik peaks olema valitud");
		}

		$$invalidate(1, errors);
		console.log(errors);
		$$invalidate(1, errors);

		if (errors.length == 0) {
			api_put("task/" + task.Id, body).then(response => response.json()).then(data => {
				console.log(task, body);

				// http://dev.local:9090/?link=786bdd99-71be-47bf-8ca8-c4a3d94d095d&dest=task&id=97
				sendEmail($user, task.worker_email, task.worker_name, "ACRM: Aeg kinnitatud / " + task.parent + " / " + task.addr, "", task.garantii_comment + "<br/>Link avamiseks: " + gen_link_worker(task.worker_link, $is_DEV));

				navigate("/thankyou", {
					state: { from: $location.pathname },
					replace: true
				});
			});
		}
	}

	function handle_change(d, t) {
		$$invalidate(0, task.date_confirmed = d, task);
		$$invalidate(0, task.time_confirmed = t, task);
	}

	return [task, errors, location, params, handle_submit, handle_change];
}

class Task extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Task;