import { S as SvelteComponent, i as init, s as safe_not_equal, a as append_styles, c as create_slot, e as element, b as attr, n as null_to_empty, t as toggle_class, d as insert, f as append, l as listen, u as update_slot_base, g as get_all_dirty_from_scope, h as get_slot_changes, j as transition_in, k as transition_out, m as detach, p as createEventDispatcher, q as identity, r as text, v as noop, w as group_outros, x as check_outros, y as getContext, z as component_subscribe, A as afterUpdate, B as binding_callbacks, C as src_url_equal, D as set_data, E as space, F as add_render_callback, G as create_in_transition, H as create_out_transition, I as bubble, J as create_bidirectional_transition, K as bind, L as create_component, M as mount_component, N as add_flush_callback, O as destroy_component, P as setContext, Q as svg_element, R as set_style, T as run_all, U as assign, V as set_attributes, W as get_spread_update, X as compute_rest_props, o as onMount, Y as onDestroy, Z as exclude_internal_props, _ as empty, $ as compute_slots } from './common/index-4e8af671.js';
import { w as writable } from './common/index-8ab5c264.js';

/* node_modules/svelte-lightbox/src/LightboxThumbnail.svelte generated by Svelte v3.43.1 */

function add_css(target) {
	append_styles(target, "svelte-1u332e1", "div.clickable.svelte-1u332e1{position:static;cursor:zoom-in}div.svelte-lightbox-unselectable.svelte-1u332e1{user-select:none;pointer-events:none}");
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[5].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (default_slot) default_slot.c();
			attr(div0, "class", div0_class_value = "" + (null_to_empty(/*classes*/ ctx[0]) + " svelte-1u332e1"));
			attr(div0, "style", /*style*/ ctx[1]);
			toggle_class(div0, "svelte-lightbox-unselectable", /*protect*/ ctx[2]);
			attr(div1, "class", "clickable svelte-1u332e1");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen(div1, "click", /*click_handler*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[4], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*classes*/ 1 && div0_class_value !== (div0_class_value = "" + (null_to_empty(/*classes*/ ctx[0]) + " svelte-1u332e1"))) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*style*/ 2) {
				attr(div0, "style", /*style*/ ctx[1]);
			}

			if (dirty & /*classes, protect*/ 5) {
				toggle_class(div0, "svelte-lightbox-unselectable", /*protect*/ ctx[2]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	const dispatch = createEventDispatcher();
	let { class: classes = '' } = $$props;
	let { style = '' } = $$props;
	let { protect = false } = $$props;
	const click_handler = () => dispatch('click');

	$$self.$$set = $$props => {
		if ('class' in $$props) $$invalidate(0, classes = $$props.class);
		if ('style' in $$props) $$invalidate(1, style = $$props.style);
		if ('protect' in $$props) $$invalidate(2, protect = $$props.protect);
		if ('$$scope' in $$props) $$invalidate(4, $$scope = $$props.$$scope);
	};

	return [classes, style, protect, dispatch, $$scope, slots, click_handler];
}

class LightboxThumbnail extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { class: 0, style: 1, protect: 2 }, add_css);
	}
}

function fade(node, {delay = 0, duration = 400, easing = identity} = {}) {
  const o = +getComputedStyle(node).opacity;
  return {
    delay,
    duration,
    easing,
    css: (t) => `opacity: ${t * o}`
  };
}

/* node_modules/svelte-lightbox/src/Modal/LightboxHeader.svelte generated by Svelte v3.43.1 */

function add_css$1(target) {
	append_styles(target, "svelte-fa0syz", "div.svelte-lightbox-header.svelte-fa0syz{width:auto;height:3rem;display:flex;justify-content:flex-end;align-items:center}div.fullscreen.svelte-fa0syz{position:fixed;z-index:5;top:0;left:0;right:0}button.svelte-fa0syz{background:transparent;font-size:3rem;border:none;color:white}button.svelte-fa0syz:hover{color:lightgray;cursor:pointer}button.svelte-fa0syz:active{background-color:transparent}button.fullscreen.svelte-fa0syz{filter:drop-shadow(0 0 5px black) drop-shadow(0 0 10px black)}");
}

// (14:4) {#if closeButton}
function create_if_block(ctx) {
	let button;
	let t;
	let button_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			t = text("×");
			attr(button, "size", /*size*/ ctx[0]);
			attr(button, "style", /*style*/ ctx[1]);
			attr(button, "class", button_class_value = "" + (null_to_empty(/*buttonClasses*/ ctx[3]) + " svelte-fa0syz"));
			toggle_class(button, "fullscreen", /*fullscreen*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[7]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*size*/ 1) {
				attr(button, "size", /*size*/ ctx[0]);
			}

			if (dirty & /*style*/ 2) {
				attr(button, "style", /*style*/ ctx[1]);
			}

			if (dirty & /*buttonClasses*/ 8 && button_class_value !== (button_class_value = "" + (null_to_empty(/*buttonClasses*/ ctx[3]) + " svelte-fa0syz"))) {
				attr(button, "class", button_class_value);
			}

			if (dirty & /*buttonClasses, fullscreen*/ 40) {
				toggle_class(button, "fullscreen", /*fullscreen*/ ctx[5]);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment$1(ctx) {
	let div;
	let div_class_value;
	let if_block = /*closeButton*/ ctx[4] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", div_class_value = "" + (null_to_empty("svelte-lightbox-header " + /*headerClasses*/ ctx[2]) + " svelte-fa0syz"));
			toggle_class(div, "fullscreen", /*fullscreen*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (/*closeButton*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*headerClasses*/ 4 && div_class_value !== (div_class_value = "" + (null_to_empty("svelte-lightbox-header " + /*headerClasses*/ ctx[2]) + " svelte-fa0syz"))) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*headerClasses, fullscreen*/ 36) {
				toggle_class(div, "fullscreen", /*fullscreen*/ ctx[5]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	const dispatch = createEventDispatcher();
	let { size = 'xs' } = $$props;
	let { style = '' } = $$props;
	let { headerClasses = '' } = $$props;
	let { buttonClasses = '' } = $$props;
	let { closeButton = true } = $$props;
	let { fullscreen = false } = $$props;
	const click_handler = () => dispatch('close');

	$$self.$$set = $$props => {
		if ('size' in $$props) $$invalidate(0, size = $$props.size);
		if ('style' in $$props) $$invalidate(1, style = $$props.style);
		if ('headerClasses' in $$props) $$invalidate(2, headerClasses = $$props.headerClasses);
		if ('buttonClasses' in $$props) $$invalidate(3, buttonClasses = $$props.buttonClasses);
		if ('closeButton' in $$props) $$invalidate(4, closeButton = $$props.closeButton);
		if ('fullscreen' in $$props) $$invalidate(5, fullscreen = $$props.fullscreen);
	};

	return [
		size,
		style,
		headerClasses,
		buttonClasses,
		closeButton,
		fullscreen,
		dispatch,
		click_handler
	];
}

class LightboxHeader extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$1,
			create_fragment$1,
			safe_not_equal,
			{
				size: 0,
				style: 1,
				headerClasses: 2,
				buttonClasses: 3,
				closeButton: 4,
				fullscreen: 5
			},
			add_css$1
		);
	}
}

const presets =  {
    expand: {
        "width": "100%",
        "maxWidth": "",
        "height": "auto",
        "maxHeight": ""
    },
    fit: {
        "width": "",
        "maxWidth": "80vw",
        "height": "",
        "maxHeight": "80vh"
    },
    fullscreen: {
        "width": "100vw",
        "maxWidth": "100vw",
        "height": "100vh",
        "maxHeight": "100vh"
    },
    scroll: {
        "width": "auto",
        "height": "auto",
        "overflow": "scroll"
    }
};

/* node_modules/svelte-lightbox/src/Modal/LightboxBody.svelte generated by Svelte v3.43.1 */

function add_css$2(target) {
	append_styles(target, "svelte-3luti8", "div.svelte-lightbox-body.svelte-3luti8{background-color:transparent;width:auto;height:auto;max-height:80vh}div.svelte-lightbox-body.fullscreen.svelte-3luti8{background-size:contain;background-repeat:no-repeat;background-position:center}div.fullscreen.svelte-3luti8{width:inherit;max-width:inherit;height:inherit;max-height:inherit}div.svelte-lightbox-unselectable.svelte-3luti8{user-select:none;pointer-events:none}div.svelte-lightbox-image-portrait.svelte-3luti8{height:90vh}div.expand.svelte-3luti8{width:90vw;height:auto;max-height:90vh}");
}

// (51:1) {:else}
function create_else_block(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[10].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[9], null);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			attr(div, "class", "svelte-3luti8");
			toggle_class(div, "svelte-lightbox-image-portrait", /*portrait*/ ctx[2]);
			toggle_class(div, "expand", /*imagePreset*/ ctx[3] == 'expand');
			toggle_class(div, "fit", /*imagePreset*/ ctx[3] == 'fit');
			toggle_class(div, "fullscreen", /*fullscreen*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			/*div_binding*/ ctx[11](div);
			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 512)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[9],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[9])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[9], dirty, null),
						null
					);
				}
			}

			if (dirty & /*portrait*/ 4) {
				toggle_class(div, "svelte-lightbox-image-portrait", /*portrait*/ ctx[2]);
			}

			if (dirty & /*imagePreset*/ 8) {
				toggle_class(div, "expand", /*imagePreset*/ ctx[3] == 'expand');
			}

			if (dirty & /*imagePreset*/ 8) {
				toggle_class(div, "fit", /*imagePreset*/ ctx[3] == 'fit');
			}

			if (dirty & /*fullscreen*/ 16) {
				toggle_class(div, "fullscreen", /*fullscreen*/ ctx[4]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
			/*div_binding*/ ctx[11](null);
		}
	};
}

// (49:1) {#if !fullscreen && image.src}
function create_if_block$1(ctx) {
	let img;
	let img_src_value;
	let img_alt_value;
	let img_style_value;

	return {
		c() {
			img = element("img");
			if (!src_url_equal(img.src, img_src_value = /*image*/ ctx[0].src)) attr(img, "src", img_src_value);
			attr(img, "alt", img_alt_value = /*image*/ ctx[0].alt);
			attr(img, "style", img_style_value = /*image*/ ctx[0].style);
			attr(img, "class", /*imageClass*/ ctx[6]);
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*image*/ 1 && !src_url_equal(img.src, img_src_value = /*image*/ ctx[0].src)) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*image*/ 1 && img_alt_value !== (img_alt_value = /*image*/ ctx[0].alt)) {
				attr(img, "alt", img_alt_value);
			}

			if (dirty & /*image*/ 1 && img_style_value !== (img_style_value = /*image*/ ctx[0].style)) {
				attr(img, "style", img_style_value);
			}

			if (dirty & /*imageClass*/ 64) {
				attr(img, "class", /*imageClass*/ ctx[6]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

function create_fragment$2(ctx) {
	let div;
	let current_block_type_index;
	let if_block;
	let div_style_value;
	let current;
	const if_block_creators = [create_if_block$1, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*fullscreen*/ ctx[4] && /*image*/ ctx[0].src) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
			attr(div, "class", "svelte-lightbox-body svelte-3luti8");

			attr(div, "style", div_style_value = /*fullscreen*/ ctx[4]
			? `background-image: url(${/*image*/ ctx[0].src || ''})`
			: '');

			toggle_class(div, "svelte-lightbox-unselectable", /*protect*/ ctx[1]);
			toggle_class(div, "fullscreen", /*fullscreen*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div, null);
			}

			if (!current || dirty & /*fullscreen, image*/ 17 && div_style_value !== (div_style_value = /*fullscreen*/ ctx[4]
			? `background-image: url(${/*image*/ ctx[0].src || ''})`
			: '')) {
				attr(div, "style", div_style_value);
			}

			if (dirty & /*protect*/ 2) {
				toggle_class(div, "svelte-lightbox-unselectable", /*protect*/ ctx[1]);
			}

			if (dirty & /*fullscreen*/ 16) {
				toggle_class(div, "fullscreen", /*fullscreen*/ ctx[4]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let imageClass;
	let $activeImageStore;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { image = {} } = $$props;
	let { protect = false } = $$props;
	let { portrait = false } = $$props;
	let { imagePreset = false } = $$props;
	let { fullscreen = false } = $$props;
	let { gallery = false } = $$props;
	const activeImageStore = getContext('svelte-lightbox-activeImage');
	component_subscribe($$self, activeImageStore, value => $$invalidate(12, $activeImageStore = value));
	let imageParent;

	const getFullscreenSrc = () => {
		// Getting image that should been displayed and taking its src
		if (imageParent) {
			let imageElement;

			if (gallery) {
				// Getting active images src from gallery
				imageElement = imageParent.firstChild.children[1].children[$activeImageStore].firstChild;
			} else {
				// In case of classic lightbox, we just grab image that is first child
				imageElement = imageParent.firstChild;
			}

			// Getting source for lightbox body background and hiding original
			$$invalidate(0, image.src = imageElement.src, image);

			imageElement.style.display = 'none';
		} else {
			queueMicrotask(getFullscreenSrc);
		}
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			imageParent = $$value;
			$$invalidate(5, imageParent);
		});
	}

	$$self.$$set = $$props => {
		if ('image' in $$props) $$invalidate(0, image = $$props.image);
		if ('protect' in $$props) $$invalidate(1, protect = $$props.protect);
		if ('portrait' in $$props) $$invalidate(2, portrait = $$props.portrait);
		if ('imagePreset' in $$props) $$invalidate(3, imagePreset = $$props.imagePreset);
		if ('fullscreen' in $$props) $$invalidate(4, fullscreen = $$props.fullscreen);
		if ('gallery' in $$props) $$invalidate(8, gallery = $$props.gallery);
		if ('$$scope' in $$props) $$invalidate(9, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*imageParent, imagePreset*/ 40) {
			 if (imageParent && imagePreset && presets[imagePreset]) {
				const imageStyle = imageParent.firstChild.style;
				const styles = Object.keys(presets[imagePreset]);

				for (let i = 0; i !== styles.length; i++) {
					imageStyle[styles[i]] = presets[imagePreset][i];
				}
			}
		}

		if ($$self.$$.dirty & /*image, imagePreset*/ 9) {
			 $$invalidate(6, imageClass = `${image.class ? image.class : ''} ${imagePreset ? imagePreset : ''}`);
		}

		if ($$self.$$.dirty & /*fullscreen, image*/ 17) {
			 if (fullscreen && !image?.src) getFullscreenSrc();
		}

		if ($$self.$$.dirty & /*fullscreen*/ 16) {
			 if (fullscreen) {
				// In case user uses fullscreen preset, we need to get image source from new image and hide it
				afterUpdate(getFullscreenSrc);
			}
		}
	};

	return [
		image,
		protect,
		portrait,
		imagePreset,
		fullscreen,
		imageParent,
		imageClass,
		activeImageStore,
		gallery,
		$$scope,
		slots,
		div_binding
	];
}

class LightboxBody extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$2,
			create_fragment$2,
			safe_not_equal,
			{
				image: 0,
				protect: 1,
				portrait: 2,
				imagePreset: 3,
				fullscreen: 4,
				gallery: 8
			},
			add_css$2
		);
	}
}

/* node_modules/svelte-lightbox/src/Modal/LightboxFooter.svelte generated by Svelte v3.43.1 */

function add_css$3(target) {
	append_styles(target, "svelte-1u8lh7d", "div.svelte-lightbox-footer.svelte-1u8lh7d{background-color:transparent;color:white;text-align:left;width:inherit;height:auto}");
}

// (18:4) {#if galleryLength}
function create_if_block$2(ctx) {
	let p;
	let t0;
	let t1_value = /*activeImage*/ ctx[3] + 1 + "";
	let t1;
	let t2;
	let t3;

	return {
		c() {
			p = element("p");
			t0 = text("Image ");
			t1 = text(t1_value);
			t2 = text(" of ");
			t3 = text(/*galleryLength*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			append(p, t1);
			append(p, t2);
			append(p, t3);
		},
		p(ctx, dirty) {
			if (dirty & /*activeImage*/ 8 && t1_value !== (t1_value = /*activeImage*/ ctx[3] + 1 + "")) set_data(t1, t1_value);
			if (dirty & /*galleryLength*/ 4) set_data(t3, /*galleryLength*/ ctx[2]);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment$3(ctx) {
	let div;
	let h2;
	let t0;
	let h5;
	let t1;
	let div_class_value;
	let if_block = /*galleryLength*/ ctx[2] && create_if_block$2(ctx);

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			t0 = space();
			h5 = element("h5");
			t1 = space();
			if (if_block) if_block.c();
			attr(div, "class", div_class_value = "" + (null_to_empty("svelte-lightbox-footer " + /*classes*/ ctx[4]) + " svelte-1u8lh7d"));
			attr(div, "style", /*style*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			h2.innerHTML = /*title*/ ctx[0];
			append(div, t0);
			append(div, h5);
			h5.innerHTML = /*description*/ ctx[1];
			append(div, t1);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (dirty & /*title*/ 1) h2.innerHTML = /*title*/ ctx[0];			if (dirty & /*description*/ 2) h5.innerHTML = /*description*/ ctx[1];
			if (/*galleryLength*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$2(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*classes*/ 16 && div_class_value !== (div_class_value = "" + (null_to_empty("svelte-lightbox-footer " + /*classes*/ ctx[4]) + " svelte-1u8lh7d"))) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*style*/ 32) {
				attr(div, "style", /*style*/ ctx[5]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let { title = '' } = $$props;
	let { description = '' } = $$props;
	let { galleryLength } = $$props;
	let { activeImage } = $$props;
	let { classes = '' } = $$props;
	let { style = '' } = $$props;

	$$self.$$set = $$props => {
		if ('title' in $$props) $$invalidate(0, title = $$props.title);
		if ('description' in $$props) $$invalidate(1, description = $$props.description);
		if ('galleryLength' in $$props) $$invalidate(2, galleryLength = $$props.galleryLength);
		if ('activeImage' in $$props) $$invalidate(3, activeImage = $$props.activeImage);
		if ('classes' in $$props) $$invalidate(4, classes = $$props.classes);
		if ('style' in $$props) $$invalidate(5, style = $$props.style);
	};

	return [title, description, galleryLength, activeImage, classes, style];
}

class LightboxFooter extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$3,
			create_fragment$3,
			safe_not_equal,
			{
				title: 0,
				description: 1,
				galleryLength: 2,
				activeImage: 3,
				classes: 4,
				style: 5
			},
			add_css$3
		);
	}
}

/* node_modules/svelte-lightbox/src/Modal/ModalCover.svelte generated by Svelte v3.43.1 */

function add_css$4(target) {
	append_styles(target, "svelte-o5rrpx", "div.svelte-o5rrpx{position:fixed;z-index:1000000!important;background-color:rgba(43, 39, 45, 0.87);top:0;bottom:0;left:0;right:0;overflow:hidden;width:100%;height:100%;display:flex;align-items:center;justify-content:center}div.svelte-o5rrpx::before{content:'';position:absolute;top:0;bottom:0;left:0;right:0;opacity:0;z-index:-1}div.svelte-o5rrpx::after{content:\"\";clear:both;display:table}");
}

function create_fragment$4(ctx) {
	let div;
	let div_intro;
	let div_outro;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[1], null);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			attr(div, "class", "svelte-o5rrpx");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*click_handler*/ ctx[3]);
				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 2)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[1],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[1])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[1], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);

			add_render_callback(() => {
				if (div_outro) div_outro.end(1);

				div_intro = create_in_transition(div, fade, {
					duration: /*transitionDuration*/ ctx[0] * 2
				});

				div_intro.start();
			});

			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			if (div_intro) div_intro.invalidate();

			div_outro = create_out_transition(div, fade, {
				duration: /*transitionDuration*/ ctx[0] / 2
			});

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
			if (detaching && div_outro) div_outro.end();
			mounted = false;
			dispose();
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { transitionDuration } = $$props;
	const dispatch = createEventDispatcher();

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('transitionDuration' in $$props) $$invalidate(0, transitionDuration = $$props.transitionDuration);
		if ('$$scope' in $$props) $$invalidate(1, $$scope = $$props.$$scope);
	};

	return [transitionDuration, $$scope, slots, click_handler];
}

class ModalCover extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$4, create_fragment$4, safe_not_equal, { transitionDuration: 0 }, add_css$4);
	}
}

/* node_modules/svelte-lightbox/src/Modal/Modal.svelte generated by Svelte v3.43.1 */

function add_css$5(target) {
	append_styles(target, "svelte-12ihcp1", "div.svelte-12ihcp1{position:relative;background-color:transparent;width:auto;height:auto;max-width:90vw;max-height:90vh}.fullscreen.svelte-12ihcp1{height:inherit;width:inherit;max-height:inherit;max-width:inherit}");
}

function create_fragment$5(ctx) {
	let div;
	let div_class_value;
	let div_transition;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[5].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			attr(div, "class", div_class_value = "" + (null_to_empty(/*modalClasses*/ ctx[0]) + " svelte-12ihcp1"));
			toggle_class(div, "fullscreen", /*fullscreen*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*click_handler*/ ctx[6]);
				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[4], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*modalClasses*/ 1 && div_class_value !== (div_class_value = "" + (null_to_empty(/*modalClasses*/ ctx[0]) + " svelte-12ihcp1"))) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*modalClasses, fullscreen*/ 5) {
				toggle_class(div, "fullscreen", /*fullscreen*/ ctx[2]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, { duration: /*transitionDuration*/ ctx[1] }, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, { duration: /*transitionDuration*/ ctx[1] }, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
			if (detaching && div_transition) div_transition.end();
			mounted = false;
			dispose();
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	const dispatch = createEventDispatcher();
	let { modalStyle } = $$props;
	let { modalClasses } = $$props;
	let { transitionDuration } = $$props;
	let { fullscreen = false } = $$props;

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('modalStyle' in $$props) $$invalidate(3, modalStyle = $$props.modalStyle);
		if ('modalClasses' in $$props) $$invalidate(0, modalClasses = $$props.modalClasses);
		if ('transitionDuration' in $$props) $$invalidate(1, transitionDuration = $$props.transitionDuration);
		if ('fullscreen' in $$props) $$invalidate(2, fullscreen = $$props.fullscreen);
		if ('$$scope' in $$props) $$invalidate(4, $$scope = $$props.$$scope);
	};

	return [
		modalClasses,
		transitionDuration,
		fullscreen,
		modalStyle,
		$$scope,
		slots,
		click_handler
	];
}

class Modal extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$5,
			create_fragment$5,
			safe_not_equal,
			{
				modalStyle: 3,
				modalClasses: 0,
				transitionDuration: 1,
				fullscreen: 2
			},
			add_css$5
		);
	}
}

/* node_modules/svelte-lightbox/src/Modal/Index.svelte generated by Svelte v3.43.1 */

function create_default_slot_2(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[17].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[32], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty[1] & /*$$scope*/ 2)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[32],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[32])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[32], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (43:4) <Modal bind:modalClasses bind:modalStyle bind:transitionDuration {fullscreen} on:click={ () => dispatch('modalClick') }>
function create_default_slot_1(ctx) {
	let header;
	let updating_closeButton;
	let t0;
	let body;
	let updating_image;
	let updating_protect;
	let updating_portrait;
	let t1;
	let footer;
	let updating_title;
	let updating_description;
	let updating_activeImage;
	let current;

	function header_closeButton_binding(value) {
		/*header_closeButton_binding*/ ctx[18](value);
	}

	let header_props = { fullscreen: /*fullscreen*/ ctx[12] };

	if (/*closeButton*/ ctx[6] !== void 0) {
		header_props.closeButton = /*closeButton*/ ctx[6];
	}

	header = new LightboxHeader({ props: header_props });
	binding_callbacks.push(() => bind(header, 'closeButton', header_closeButton_binding));
	header.$on("close", /*close_handler*/ ctx[19]);

	function body_image_binding(value) {
		/*body_image_binding*/ ctx[20](value);
	}

	function body_protect_binding(value) {
		/*body_protect_binding*/ ctx[21](value);
	}

	function body_portrait_binding(value) {
		/*body_portrait_binding*/ ctx[22](value);
	}

	let body_props = {
		imagePreset: /*imagePreset*/ ctx[8],
		fullscreen: /*fullscreen*/ ctx[12],
		gallery: !!/*gallery*/ ctx[7].length,
		$$slots: { default: [create_default_slot_2] },
		$$scope: { ctx }
	};

	if (/*image*/ ctx[3] !== void 0) {
		body_props.image = /*image*/ ctx[3];
	}

	if (/*protect*/ ctx[4] !== void 0) {
		body_props.protect = /*protect*/ ctx[4];
	}

	if (/*portrait*/ ctx[5] !== void 0) {
		body_props.portrait = /*portrait*/ ctx[5];
	}

	body = new LightboxBody({ props: body_props });
	binding_callbacks.push(() => bind(body, 'image', body_image_binding));
	binding_callbacks.push(() => bind(body, 'protect', body_protect_binding));
	binding_callbacks.push(() => bind(body, 'portrait', body_portrait_binding));

	function footer_title_binding(value) {
		/*footer_title_binding*/ ctx[23](value);
	}

	function footer_description_binding(value) {
		/*footer_description_binding*/ ctx[24](value);
	}

	function footer_activeImage_binding(value) {
		/*footer_activeImage_binding*/ ctx[25](value);
	}

	let footer_props = {
		galleryLength: /*gallery*/ ctx[7] ? /*gallery*/ ctx[7].length : false
	};

	if (/*actualTitle*/ ctx[10] !== void 0) {
		footer_props.title = /*actualTitle*/ ctx[10];
	}

	if (/*actualDescription*/ ctx[11] !== void 0) {
		footer_props.description = /*actualDescription*/ ctx[11];
	}

	if (/*$activeImageStore*/ ctx[9] !== void 0) {
		footer_props.activeImage = /*$activeImageStore*/ ctx[9];
	}

	footer = new LightboxFooter({ props: footer_props });
	binding_callbacks.push(() => bind(footer, 'title', footer_title_binding));
	binding_callbacks.push(() => bind(footer, 'description', footer_description_binding));
	binding_callbacks.push(() => bind(footer, 'activeImage', footer_activeImage_binding));

	return {
		c() {
			create_component(header.$$.fragment);
			t0 = space();
			create_component(body.$$.fragment);
			t1 = space();
			create_component(footer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t0, anchor);
			mount_component(body, target, anchor);
			insert(target, t1, anchor);
			mount_component(footer, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const header_changes = {};
			if (dirty[0] & /*fullscreen*/ 4096) header_changes.fullscreen = /*fullscreen*/ ctx[12];

			if (!updating_closeButton && dirty[0] & /*closeButton*/ 64) {
				updating_closeButton = true;
				header_changes.closeButton = /*closeButton*/ ctx[6];
				add_flush_callback(() => updating_closeButton = false);
			}

			header.$set(header_changes);
			const body_changes = {};
			if (dirty[0] & /*imagePreset*/ 256) body_changes.imagePreset = /*imagePreset*/ ctx[8];
			if (dirty[0] & /*fullscreen*/ 4096) body_changes.fullscreen = /*fullscreen*/ ctx[12];
			if (dirty[0] & /*gallery*/ 128) body_changes.gallery = !!/*gallery*/ ctx[7].length;

			if (dirty[1] & /*$$scope*/ 2) {
				body_changes.$$scope = { dirty, ctx };
			}

			if (!updating_image && dirty[0] & /*image*/ 8) {
				updating_image = true;
				body_changes.image = /*image*/ ctx[3];
				add_flush_callback(() => updating_image = false);
			}

			if (!updating_protect && dirty[0] & /*protect*/ 16) {
				updating_protect = true;
				body_changes.protect = /*protect*/ ctx[4];
				add_flush_callback(() => updating_protect = false);
			}

			if (!updating_portrait && dirty[0] & /*portrait*/ 32) {
				updating_portrait = true;
				body_changes.portrait = /*portrait*/ ctx[5];
				add_flush_callback(() => updating_portrait = false);
			}

			body.$set(body_changes);
			const footer_changes = {};
			if (dirty[0] & /*gallery*/ 128) footer_changes.galleryLength = /*gallery*/ ctx[7] ? /*gallery*/ ctx[7].length : false;

			if (!updating_title && dirty[0] & /*actualTitle*/ 1024) {
				updating_title = true;
				footer_changes.title = /*actualTitle*/ ctx[10];
				add_flush_callback(() => updating_title = false);
			}

			if (!updating_description && dirty[0] & /*actualDescription*/ 2048) {
				updating_description = true;
				footer_changes.description = /*actualDescription*/ ctx[11];
				add_flush_callback(() => updating_description = false);
			}

			if (!updating_activeImage && dirty[0] & /*$activeImageStore*/ 512) {
				updating_activeImage = true;
				footer_changes.activeImage = /*$activeImageStore*/ ctx[9];
				add_flush_callback(() => updating_activeImage = false);
			}

			footer.$set(footer_changes);
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(body.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(body.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(header, detaching);
			if (detaching) detach(t0);
			destroy_component(body, detaching);
			if (detaching) detach(t1);
			destroy_component(footer, detaching);
		}
	};
}

// (42:0) <ModalCover bind:transitionDuration on:click={ () => dispatch('topModalClick') }>
function create_default_slot(ctx) {
	let modal;
	let updating_modalClasses;
	let updating_modalStyle;
	let updating_transitionDuration;
	let current;

	function modal_modalClasses_binding(value) {
		/*modal_modalClasses_binding*/ ctx[26](value);
	}

	function modal_modalStyle_binding(value) {
		/*modal_modalStyle_binding*/ ctx[27](value);
	}

	function modal_transitionDuration_binding(value) {
		/*modal_transitionDuration_binding*/ ctx[28](value);
	}

	let modal_props = {
		fullscreen: /*fullscreen*/ ctx[12],
		$$slots: { default: [create_default_slot_1] },
		$$scope: { ctx }
	};

	if (/*modalClasses*/ ctx[0] !== void 0) {
		modal_props.modalClasses = /*modalClasses*/ ctx[0];
	}

	if (/*modalStyle*/ ctx[1] !== void 0) {
		modal_props.modalStyle = /*modalStyle*/ ctx[1];
	}

	if (/*transitionDuration*/ ctx[2] !== void 0) {
		modal_props.transitionDuration = /*transitionDuration*/ ctx[2];
	}

	modal = new Modal({ props: modal_props });
	binding_callbacks.push(() => bind(modal, 'modalClasses', modal_modalClasses_binding));
	binding_callbacks.push(() => bind(modal, 'modalStyle', modal_modalStyle_binding));
	binding_callbacks.push(() => bind(modal, 'transitionDuration', modal_transitionDuration_binding));
	modal.$on("click", /*click_handler*/ ctx[29]);

	return {
		c() {
			create_component(modal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modal, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const modal_changes = {};
			if (dirty[0] & /*fullscreen*/ 4096) modal_changes.fullscreen = /*fullscreen*/ ctx[12];

			if (dirty[0] & /*gallery, actualTitle, actualDescription, $activeImageStore, imagePreset, fullscreen, image, protect, portrait, closeButton*/ 8184 | dirty[1] & /*$$scope*/ 2) {
				modal_changes.$$scope = { dirty, ctx };
			}

			if (!updating_modalClasses && dirty[0] & /*modalClasses*/ 1) {
				updating_modalClasses = true;
				modal_changes.modalClasses = /*modalClasses*/ ctx[0];
				add_flush_callback(() => updating_modalClasses = false);
			}

			if (!updating_modalStyle && dirty[0] & /*modalStyle*/ 2) {
				updating_modalStyle = true;
				modal_changes.modalStyle = /*modalStyle*/ ctx[1];
				add_flush_callback(() => updating_modalStyle = false);
			}

			if (!updating_transitionDuration && dirty[0] & /*transitionDuration*/ 4) {
				updating_transitionDuration = true;
				modal_changes.transitionDuration = /*transitionDuration*/ ctx[2];
				add_flush_callback(() => updating_transitionDuration = false);
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modal, detaching);
		}
	};
}

function create_fragment$6(ctx) {
	let modalcover;
	let updating_transitionDuration;
	let current;

	function modalcover_transitionDuration_binding(value) {
		/*modalcover_transitionDuration_binding*/ ctx[30](value);
	}

	let modalcover_props = {
		$$slots: { default: [create_default_slot] },
		$$scope: { ctx }
	};

	if (/*transitionDuration*/ ctx[2] !== void 0) {
		modalcover_props.transitionDuration = /*transitionDuration*/ ctx[2];
	}

	modalcover = new ModalCover({ props: modalcover_props });
	binding_callbacks.push(() => bind(modalcover, 'transitionDuration', modalcover_transitionDuration_binding));
	modalcover.$on("click", /*click_handler_1*/ ctx[31]);

	return {
		c() {
			create_component(modalcover.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modalcover, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const modalcover_changes = {};

			if (dirty[0] & /*fullscreen, modalClasses, modalStyle, transitionDuration, gallery, actualTitle, actualDescription, $activeImageStore, imagePreset, image, protect, portrait, closeButton*/ 8191 | dirty[1] & /*$$scope*/ 2) {
				modalcover_changes.$$scope = { dirty, ctx };
			}

			if (!updating_transitionDuration && dirty[0] & /*transitionDuration*/ 4) {
				updating_transitionDuration = true;
				modalcover_changes.transitionDuration = /*transitionDuration*/ ctx[2];
				add_flush_callback(() => updating_transitionDuration = false);
			}

			modalcover.$set(modalcover_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modalcover.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modalcover.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modalcover, detaching);
		}
	};
}

function instance$6($$self, $$props, $$invalidate) {
	let fullscreen;
	let $activeImageStore;
	let { $$slots: slots = {}, $$scope } = $$props;
	const dispatch = createEventDispatcher();
	let { modalClasses = '' } = $$props;
	let { modalStyle = '' } = $$props;
	let { transitionDuration = 500 } = $$props;
	let { image = {} } = $$props;
	let { protect = false } = $$props;
	let { portrait = false } = $$props;
	let { title = '' } = $$props;
	let { description = '' } = $$props;
	let { gallery = [] } = $$props;
	let { imagePreset } = $$props;
	let { closeButton } = $$props;
	const activeImageStore = new writable(0);
	component_subscribe($$self, activeImageStore, value => $$invalidate(9, $activeImageStore = value));
	let actualTitle;
	let actualDescription;
	setContext('svelte-lightbox-activeImage', activeImageStore);

	function header_closeButton_binding(value) {
		closeButton = value;
		$$invalidate(6, closeButton);
	}

	const close_handler = () => dispatch('close');

	function body_image_binding(value) {
		image = value;
		$$invalidate(3, image);
	}

	function body_protect_binding(value) {
		protect = value;
		$$invalidate(4, protect);
	}

	function body_portrait_binding(value) {
		portrait = value;
		$$invalidate(5, portrait);
	}

	function footer_title_binding(value) {
		actualTitle = value;
		(((($$invalidate(10, actualTitle), $$invalidate(15, title)), $$invalidate(7, gallery)), $$invalidate(16, description)), $$invalidate(9, $activeImageStore));
	}

	function footer_description_binding(value) {
		actualDescription = value;
		(((($$invalidate(11, actualDescription), $$invalidate(16, description)), $$invalidate(7, gallery)), $$invalidate(15, title)), $$invalidate(9, $activeImageStore));
	}

	function footer_activeImage_binding(value) {
		$activeImageStore = value;
		activeImageStore.set($activeImageStore);
	}

	function modal_modalClasses_binding(value) {
		modalClasses = value;
		$$invalidate(0, modalClasses);
	}

	function modal_modalStyle_binding(value) {
		modalStyle = value;
		$$invalidate(1, modalStyle);
	}

	function modal_transitionDuration_binding(value) {
		transitionDuration = value;
		$$invalidate(2, transitionDuration);
	}

	const click_handler = () => dispatch('modalClick');

	function modalcover_transitionDuration_binding(value) {
		transitionDuration = value;
		$$invalidate(2, transitionDuration);
	}

	const click_handler_1 = () => dispatch('topModalClick');

	$$self.$$set = $$props => {
		if ('modalClasses' in $$props) $$invalidate(0, modalClasses = $$props.modalClasses);
		if ('modalStyle' in $$props) $$invalidate(1, modalStyle = $$props.modalStyle);
		if ('transitionDuration' in $$props) $$invalidate(2, transitionDuration = $$props.transitionDuration);
		if ('image' in $$props) $$invalidate(3, image = $$props.image);
		if ('protect' in $$props) $$invalidate(4, protect = $$props.protect);
		if ('portrait' in $$props) $$invalidate(5, portrait = $$props.portrait);
		if ('title' in $$props) $$invalidate(15, title = $$props.title);
		if ('description' in $$props) $$invalidate(16, description = $$props.description);
		if ('gallery' in $$props) $$invalidate(7, gallery = $$props.gallery);
		if ('imagePreset' in $$props) $$invalidate(8, imagePreset = $$props.imagePreset);
		if ('closeButton' in $$props) $$invalidate(6, closeButton = $$props.closeButton);
		if ('$$scope' in $$props) $$invalidate(32, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*title*/ 32768) {
			// For variable title and description, we need to define this auxiliary variables
			 $$invalidate(10, actualTitle = title);
		}

		if ($$self.$$.dirty[0] & /*description*/ 65536) {
			 $$invalidate(11, actualDescription = description);
		}

		if ($$self.$$.dirty[0] & /*gallery, title, description, $activeImageStore*/ 98944) {
			// If there is not universal title or description for gallery, we will display individual title and description
			 if (gallery && !title && !description) {
				$$invalidate(10, actualTitle = gallery[$activeImageStore].title);
				$$invalidate(11, actualDescription = gallery[$activeImageStore].description);
			}
		}

		if ($$self.$$.dirty[0] & /*imagePreset*/ 256) {
			 $$invalidate(12, fullscreen = imagePreset === 'fullscreen');
		}
	};

	return [
		modalClasses,
		modalStyle,
		transitionDuration,
		image,
		protect,
		portrait,
		closeButton,
		gallery,
		imagePreset,
		$activeImageStore,
		actualTitle,
		actualDescription,
		fullscreen,
		dispatch,
		activeImageStore,
		title,
		description,
		slots,
		header_closeButton_binding,
		close_handler,
		body_image_binding,
		body_protect_binding,
		body_portrait_binding,
		footer_title_binding,
		footer_description_binding,
		footer_activeImage_binding,
		modal_modalClasses_binding,
		modal_modalStyle_binding,
		modal_transitionDuration_binding,
		click_handler,
		modalcover_transitionDuration_binding,
		click_handler_1,
		$$scope
	];
}

class Index extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$6,
			create_fragment$6,
			safe_not_equal,
			{
				modalClasses: 0,
				modalStyle: 1,
				transitionDuration: 2,
				image: 3,
				protect: 4,
				portrait: 5,
				title: 15,
				description: 16,
				gallery: 7,
				imagePreset: 8,
				closeButton: 6
			},
			null,
			[-1, -1]
		);
	}
}

/* node_modules/svelte-lightbox/src/Gallery/InternalGallery.svelte generated by Svelte v3.43.1 */

function add_css$6(target) {
	append_styles(target, "svelte-1lrmlbr", "div.svelte-1lrmlbr{max-height:inherit}div.fullscreen.svelte-1lrmlbr{height:100%;width:100%}.arrow.svelte-1lrmlbr{fill:none;stroke:var(--svelte-lightbox-arrows-color);stroke-linecap:round;stroke-linejoin:bevel;stroke-width:1.5px;margin:10px}button.svelte-1lrmlbr{background:transparent;border:none;font-size:1rem;width:50%;height:100%}button.svelte-1lrmlbr:active{background:transparent}button.svelte-1lrmlbr:disabled{color:gray}button:disabled.hideDisabled.svelte-1lrmlbr{visibility:hidden}.wrapper.svelte-1lrmlbr{position:relative;display:flex;width:auto;height:auto}.previous-button.svelte-1lrmlbr{position:absolute;top:0;bottom:0;left:0;right:50%;z-index:4;text-align:left}.slot.svelte-1lrmlbr{order:1;display:flex;justify-content:center}.next-button.svelte-1lrmlbr{position:absolute;top:0;bottom:0;right:0;z-index:4;text-align:right}svg.svelte-1lrmlbr{height:5rem}");
}

function create_fragment$7(ctx) {
	let div1;
	let button0;
	let svg0;
	let g0;
	let path0;
	let button0_disabled_value;
	let t0;
	let div0;
	let t1;
	let button1;
	let svg1;
	let g1;
	let path1;
	let button1_disabled_value;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[19].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[18], null);

	return {
		c() {
			div1 = element("div");
			button0 = element("button");
			svg0 = svg_element("svg");
			g0 = svg_element("g");
			path0 = svg_element("path");
			t0 = space();
			div0 = element("div");
			if (default_slot) default_slot.c();
			t1 = space();
			button1 = element("button");
			svg1 = svg_element("svg");
			g1 = svg_element("g");
			path1 = svg_element("path");
			attr(path0, "class", "arrow svelte-1lrmlbr");
			attr(path0, "d", "M8.7,7.22,4.59,11.33a1,1,0,0,0,0,1.41l4,4");
			attr(svg0, "viewBox", "0 0 24 24");
			attr(svg0, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg0, "class", "svelte-1lrmlbr");
			button0.disabled = button0_disabled_value = /*galleryArrowsCharacter*/ ctx[4] !== 'loop' && /*activeImage*/ ctx[3] === 0;
			attr(button0, "class", "previous-button svelte-1lrmlbr");
			toggle_class(button0, "hideDisabled", /*galleryArrowsCharacter*/ ctx[4] === 'hide');
			attr(div0, "class", "slot svelte-1lrmlbr");
			attr(path1, "d", "M15.3,16.78l4.11-4.11a1,1,0,0,0,0-1.41l-4-4");
			attr(path1, "class", "arrow svelte-1lrmlbr");
			attr(svg1, "viewBox", "0 0 24 24");
			attr(svg1, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg1, "class", "svelte-1lrmlbr");
			button1.disabled = button1_disabled_value = /*galleryArrowsCharacter*/ ctx[4] !== 'loop' && /*activeImage*/ ctx[3] === /*images*/ ctx[1]?.length - 1;
			attr(button1, "class", "next-button svelte-1lrmlbr");
			toggle_class(button1, "hideDisabled", /*galleryArrowsCharacter*/ ctx[4] === 'hide');
			attr(div1, "class", "wrapper svelte-1lrmlbr");
			set_style(div1, "--svelte-lightbox-arrows-color", /*galleryArrowsColor*/ ctx[5]);
			toggle_class(div1, "fullscreen", /*fullscreen*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, button0);
			append(button0, svg0);
			append(svg0, g0);
			append(g0, path0);
			append(div1, t0);
			append(div1, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			/*div0_binding*/ ctx[21](div0);
			append(div1, t1);
			append(div1, button1);
			append(button1, svg1);
			append(svg1, g1);
			append(g1, path1);
			current = true;

			if (!mounted) {
				dispose = [
					listen(window, "keydown", /*keydown_handler*/ ctx[20]),
					listen(button0, "click", /*previousImage*/ ctx[10]),
					listen(button1, "click", /*nextImage*/ ctx[11])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*galleryArrowsCharacter, activeImage*/ 24 && button0_disabled_value !== (button0_disabled_value = /*galleryArrowsCharacter*/ ctx[4] !== 'loop' && /*activeImage*/ ctx[3] === 0)) {
				button0.disabled = button0_disabled_value;
			}

			if (dirty & /*galleryArrowsCharacter*/ 16) {
				toggle_class(button0, "hideDisabled", /*galleryArrowsCharacter*/ ctx[4] === 'hide');
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 262144)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[18],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[18])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[18], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*galleryArrowsCharacter, activeImage, images*/ 26 && button1_disabled_value !== (button1_disabled_value = /*galleryArrowsCharacter*/ ctx[4] !== 'loop' && /*activeImage*/ ctx[3] === /*images*/ ctx[1]?.length - 1)) {
				button1.disabled = button1_disabled_value;
			}

			if (dirty & /*galleryArrowsCharacter*/ 16) {
				toggle_class(button1, "hideDisabled", /*galleryArrowsCharacter*/ ctx[4] === 'hide');
			}

			if (!current || dirty & /*galleryArrowsColor*/ 32) {
				set_style(div1, "--svelte-lightbox-arrows-color", /*galleryArrowsColor*/ ctx[5]);
			}

			if (dirty & /*fullscreen*/ 4) {
				toggle_class(div1, "fullscreen", /*fullscreen*/ ctx[2]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (default_slot) default_slot.d(detaching);
			/*div0_binding*/ ctx[21](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$7($$self, $$props, $$invalidate) {
	let activeImage;
	let galleryArrowsColor;
	let galleryArrowsCharacter;
	let disableKeyboardArrowsControl;
	let fullscreen;
	let $keyboardControlStore;
	let $arrowsCharacterStore;
	let $arrowsColorStore;
	let $activeImageStore;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { imagePreset = '' } = $$props;
	const activeImageStore = getContext('svelte-lightbox-activeImage');
	component_subscribe($$self, activeImageStore, value => $$invalidate(17, $activeImageStore = value));
	const arrowsColorStore = new writable('black');
	component_subscribe($$self, arrowsColorStore, value => $$invalidate(16, $arrowsColorStore = value));
	const arrowsCharacterStore = new writable('unset');
	component_subscribe($$self, arrowsCharacterStore, value => $$invalidate(15, $arrowsCharacterStore = value));
	const keyboardControlStore = new writable(false);
	component_subscribe($$self, keyboardControlStore, value => $$invalidate(14, $keyboardControlStore = value));

	// Here will be stored markup that will user put inside of this component
	let slotContent;

	// Auxiliary variable for storing elements with image that user has provided
	let images;

	const previousImage = () => {
		if (activeImage === 0) {
			if (galleryArrowsCharacter === 'loop') {
				activeImageStore.set(images.length - 1);
			}
		} else {
			activeImageStore.set(activeImage - 1);
		}
	};

	const nextImage = () => {
		if (activeImage === images.length - 1) {
			if (galleryArrowsCharacter === 'loop') {
				activeImageStore.set(0);
			}
		} else {
			activeImageStore.set(activeImage + 1);
		}
	};

	const handleKey = event => {
		if (!disableKeyboardArrowsControl) {
			switch (event.key) {
				case 'ArrowLeft':
					{
						previousImage();
						break;
					}
				case 'ArrowRight':
					{
						nextImage();
						break;
					}
			}
		}
	};

	setContext('svelte-lightbox-galleryArrowsColor', arrowsColorStore);
	setContext('svelte-lightbox-galleryArrowsCharacter', arrowsCharacterStore);
	setContext('svelte-lightbox-disableKeyboardArrowsControl', keyboardControlStore);
	const keydown_handler = event => handleKey(event);

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			slotContent = $$value;
			$$invalidate(0, slotContent);
		});
	}

	$$self.$$set = $$props => {
		if ('imagePreset' in $$props) $$invalidate(13, imagePreset = $$props.imagePreset);
		if ('$$scope' in $$props) $$invalidate(18, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$activeImageStore*/ 131072) {
			 $$invalidate(3, activeImage = $activeImageStore);
		}

		if ($$self.$$.dirty & /*$arrowsColorStore*/ 65536) {
			 $$invalidate(5, galleryArrowsColor = $arrowsColorStore);
		}

		if ($$self.$$.dirty & /*$arrowsCharacterStore*/ 32768) {
			 $$invalidate(4, galleryArrowsCharacter = $arrowsCharacterStore);
		}

		if ($$self.$$.dirty & /*$keyboardControlStore*/ 16384) {
			 disableKeyboardArrowsControl = $keyboardControlStore;
		}

		if ($$self.$$.dirty & /*slotContent*/ 1) {
			// Every time, when contents of this component changes, images will be updated
			 $$invalidate(1, images = slotContent?.children);
		}

		if ($$self.$$.dirty & /*imagePreset*/ 8192) {
			 $$invalidate(2, fullscreen = imagePreset === 'fullscreen');
		}

		if ($$self.$$.dirty & /*images, activeImage, fullscreen*/ 14) {
			 {
				/*
When activeImage or images array changes, checks if active image points to existing image and then displays it,
if selected image doesn't exist, then logs out error, these error normally does not occur, only in cases when
activeImage is controlled programmatically
 */
				if (images && activeImage < images.length) {
					Object.values(images).forEach(img => {
						img.hidden = true;
						return img;
					});

					if (!fullscreen) {
						$$invalidate(1, images[activeImage].hidden = false, images);
					}
				} else if (images && activeImage >= images.length) {
					console.error("LightboxGallery: Selected image doesn't exist, invalid activeImage");
				}
			}
		}
	};

	return [
		slotContent,
		images,
		fullscreen,
		activeImage,
		galleryArrowsCharacter,
		galleryArrowsColor,
		activeImageStore,
		arrowsColorStore,
		arrowsCharacterStore,
		keyboardControlStore,
		previousImage,
		nextImage,
		handleKey,
		imagePreset,
		$keyboardControlStore,
		$arrowsCharacterStore,
		$arrowsColorStore,
		$activeImageStore,
		$$scope,
		slots,
		keydown_handler,
		div0_binding
	];
}

class InternalGallery extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$7, create_fragment$7, safe_not_equal, { imagePreset: 13 }, add_css$6);
	}
}

/* node_modules/svelte-lightbox/src/Modal/BodyChild.svelte generated by Svelte v3.43.1 */

function create_fragment$8(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);
	let div_levels = [/*$$restProps*/ ctx[1]];
	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			set_attributes(div, div_data);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			/*div_binding*/ ctx[4](div);
			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}

			set_attributes(div, div_data = get_spread_update(div_levels, [dirty & /*$$restProps*/ 2 && /*$$restProps*/ ctx[1]]));
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
			/*div_binding*/ ctx[4](null);
		}
	};
}

function instance$8($$self, $$props, $$invalidate) {
	const omit_props_names = [];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let targetElement;
	let child;

	const stackTarget = () => {
		child = document.createElement('div');
		document.body.appendChild(child);
		child.appendChild(targetElement);
	};

	const removeTarget = () => {
		if (typeof document !== 'undefined') {
			document.body.removeChild(child);
		}
	};

	onMount(stackTarget);
	onDestroy(removeTarget);

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			targetElement = $$value;
			$$invalidate(0, targetElement);
		});
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(1, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('$$scope' in $$new_props) $$invalidate(2, $$scope = $$new_props.$$scope);
	};

	return [targetElement, $$restProps, $$scope, slots, div_binding];
}

class BodyChild extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$8, create_fragment$8, safe_not_equal, {});
	}
}

/* node_modules/svelte-lightbox/src/Lightbox.svelte generated by Svelte v3.43.1 */
const get_thumbnail_slot_changes_1 = dirty => ({});
const get_thumbnail_slot_context_1 = ctx => ({});
const get_image_slot_changes = dirty => ({});
const get_image_slot_context = ctx => ({});
const get_thumbnail_slot_changes = dirty => ({});
const get_thumbnail_slot_context = ctx => ({});

// (81:1) {:else}
function create_else_block_1(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[22].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[37], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty[1] & /*$$scope*/ 64)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[37],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[37])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[37], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (79:1) {#if thumbnail || gallery}
function create_if_block_4(ctx) {
	let current;
	const thumbnail_slot_template = /*#slots*/ ctx[22].thumbnail;
	const thumbnail_slot = create_slot(thumbnail_slot_template, ctx, /*$$scope*/ ctx[37], get_thumbnail_slot_context);

	return {
		c() {
			if (thumbnail_slot) thumbnail_slot.c();
		},
		m(target, anchor) {
			if (thumbnail_slot) {
				thumbnail_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (thumbnail_slot) {
				if (thumbnail_slot.p && (!current || dirty[1] & /*$$scope*/ 64)) {
					update_slot_base(
						thumbnail_slot,
						thumbnail_slot_template,
						ctx,
						/*$$scope*/ ctx[37],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[37])
						: get_slot_changes(thumbnail_slot_template, /*$$scope*/ ctx[37], dirty, get_thumbnail_slot_changes),
						get_thumbnail_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(thumbnail_slot, local);
			current = true;
		},
		o(local) {
			transition_out(thumbnail_slot, local);
			current = false;
		},
		d(detaching) {
			if (thumbnail_slot) thumbnail_slot.d(detaching);
		}
	};
}

// (78:0) <Thumbnail bind:thumbnailClasses bind:thumbnailStyle bind:protect on:click={toggle}>
function create_default_slot_3(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_4, create_else_block_1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*thumbnail*/ ctx[14] || /*gallery*/ ctx[4]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (86:0) {#if isVisible}
function create_if_block$3(ctx) {
	let bodychild;
	let current;

	bodychild = new BodyChild({
			props: {
				$$slots: { default: [create_default_slot$1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(bodychild.$$.fragment);
		},
		m(target, anchor) {
			mount_component(bodychild, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const bodychild_changes = {};

			if (dirty[0] & /*modalClasses, modalStyle, transitionDuration, image, protect, portrait, title, description, gallery, imagePreset, closeButton, thumbnail, $$slots*/ 548860 | dirty[1] & /*$$scope*/ 64) {
				bodychild_changes.$$scope = { dirty, ctx };
			}

			bodychild.$set(bodychild_changes);
		},
		i(local) {
			if (current) return;
			transition_in(bodychild.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(bodychild.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(bodychild, detaching);
		}
	};
}

// (103:3) {:else}
function create_else_block$1(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[22].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[37], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty[1] & /*$$scope*/ 64)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[37],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[37])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[37], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (93:21) 
function create_if_block_2(ctx) {
	let internalgallery;
	let current;

	internalgallery = new InternalGallery({
			props: {
				imagePreset: /*imagePreset*/ ctx[11],
				$$slots: { default: [create_default_slot_2$1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(internalgallery.$$.fragment);
		},
		m(target, anchor) {
			mount_component(internalgallery, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const internalgallery_changes = {};
			if (dirty[0] & /*imagePreset*/ 2048) internalgallery_changes.imagePreset = /*imagePreset*/ ctx[11];

			if (dirty[0] & /*$$slots*/ 524288 | dirty[1] & /*$$scope*/ 64) {
				internalgallery_changes.$$scope = { dirty, ctx };
			}

			internalgallery.$set(internalgallery_changes);
		},
		i(local) {
			if (current) return;
			transition_in(internalgallery.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(internalgallery.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(internalgallery, detaching);
		}
	};
}

// (91:3) {#if thumbnail}
function create_if_block_1(ctx) {
	let current;
	const image_slot_template = /*#slots*/ ctx[22].image;
	const image_slot = create_slot(image_slot_template, ctx, /*$$scope*/ ctx[37], get_image_slot_context);

	return {
		c() {
			if (image_slot) image_slot.c();
		},
		m(target, anchor) {
			if (image_slot) {
				image_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (image_slot) {
				if (image_slot.p && (!current || dirty[1] & /*$$scope*/ 64)) {
					update_slot_base(
						image_slot,
						image_slot_template,
						ctx,
						/*$$scope*/ ctx[37],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[37])
						: get_slot_changes(image_slot_template, /*$$scope*/ ctx[37], dirty, get_image_slot_changes),
						get_image_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(image_slot, local);
			current = true;
		},
		o(local) {
			transition_out(image_slot, local);
			current = false;
		},
		d(detaching) {
			if (image_slot) image_slot.d(detaching);
		}
	};
}

// (95:5) {#if $$slots.thumbnail}
function create_if_block_3(ctx) {
	let div;
	let current;
	const thumbnail_slot_template = /*#slots*/ ctx[22].thumbnail;
	const thumbnail_slot = create_slot(thumbnail_slot_template, ctx, /*$$scope*/ ctx[37], get_thumbnail_slot_context_1);

	return {
		c() {
			div = element("div");
			if (thumbnail_slot) thumbnail_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (thumbnail_slot) {
				thumbnail_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (thumbnail_slot) {
				if (thumbnail_slot.p && (!current || dirty[1] & /*$$scope*/ 64)) {
					update_slot_base(
						thumbnail_slot,
						thumbnail_slot_template,
						ctx,
						/*$$scope*/ ctx[37],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[37])
						: get_slot_changes(thumbnail_slot_template, /*$$scope*/ ctx[37], dirty, get_thumbnail_slot_changes_1),
						get_thumbnail_slot_context_1
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(thumbnail_slot, local);
			current = true;
		},
		o(local) {
			transition_out(thumbnail_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (thumbnail_slot) thumbnail_slot.d(detaching);
		}
	};
}

// (94:4) <InternalGallery {imagePreset}>
function create_default_slot_2$1(ctx) {
	let t;
	let current;
	let if_block = /*$$slots*/ ctx[19].thumbnail && create_if_block_3(ctx);
	const default_slot_template = /*#slots*/ ctx[22].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[37], null);

	return {
		c() {
			if (if_block) if_block.c();
			t = space();
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t, anchor);

			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (/*$$slots*/ ctx[19].thumbnail) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*$$slots*/ 524288) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty[1] & /*$$scope*/ 64)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[37],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[37])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[37], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (88:2) <Modal bind:modalClasses bind:modalStyle bind:transitionDuration bind:image bind:protect          bind:portrait bind:title bind:description bind:gallery bind:imagePreset bind:closeButton          on:close={close} on:topModalClick={coverClick} on:modalClick={modalClick}>
function create_default_slot_1$1(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_1, create_if_block_2, create_else_block$1];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (/*thumbnail*/ ctx[14]) return 0;
		if (/*gallery*/ ctx[4]) return 1;
		return 2;
	}

	current_block_type_index = select_block_type_1(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (87:1) <BodyChild>
function create_default_slot$1(ctx) {
	let modal;
	let updating_modalClasses;
	let updating_modalStyle;
	let updating_transitionDuration;
	let updating_image;
	let updating_protect;
	let updating_portrait;
	let updating_title;
	let updating_description;
	let updating_gallery;
	let updating_imagePreset;
	let updating_closeButton;
	let current;

	function modal_modalClasses_binding(value) {
		/*modal_modalClasses_binding*/ ctx[26](value);
	}

	function modal_modalStyle_binding(value) {
		/*modal_modalStyle_binding*/ ctx[27](value);
	}

	function modal_transitionDuration_binding(value) {
		/*modal_transitionDuration_binding*/ ctx[28](value);
	}

	function modal_image_binding(value) {
		/*modal_image_binding*/ ctx[29](value);
	}

	function modal_protect_binding(value) {
		/*modal_protect_binding*/ ctx[30](value);
	}

	function modal_portrait_binding(value) {
		/*modal_portrait_binding*/ ctx[31](value);
	}

	function modal_title_binding(value) {
		/*modal_title_binding*/ ctx[32](value);
	}

	function modal_description_binding(value) {
		/*modal_description_binding*/ ctx[33](value);
	}

	function modal_gallery_binding(value) {
		/*modal_gallery_binding*/ ctx[34](value);
	}

	function modal_imagePreset_binding(value) {
		/*modal_imagePreset_binding*/ ctx[35](value);
	}

	function modal_closeButton_binding(value) {
		/*modal_closeButton_binding*/ ctx[36](value);
	}

	let modal_props = {
		$$slots: { default: [create_default_slot_1$1] },
		$$scope: { ctx }
	};

	if (/*modalClasses*/ ctx[2] !== void 0) {
		modal_props.modalClasses = /*modalClasses*/ ctx[2];
	}

	if (/*modalStyle*/ ctx[3] !== void 0) {
		modal_props.modalStyle = /*modalStyle*/ ctx[3];
	}

	if (/*transitionDuration*/ ctx[7] !== void 0) {
		modal_props.transitionDuration = /*transitionDuration*/ ctx[7];
	}

	if (/*image*/ ctx[9] !== void 0) {
		modal_props.image = /*image*/ ctx[9];
	}

	if (/*protect*/ ctx[8] !== void 0) {
		modal_props.protect = /*protect*/ ctx[8];
	}

	if (/*portrait*/ ctx[10] !== void 0) {
		modal_props.portrait = /*portrait*/ ctx[10];
	}

	if (/*title*/ ctx[5] !== void 0) {
		modal_props.title = /*title*/ ctx[5];
	}

	if (/*description*/ ctx[6] !== void 0) {
		modal_props.description = /*description*/ ctx[6];
	}

	if (/*gallery*/ ctx[4] !== void 0) {
		modal_props.gallery = /*gallery*/ ctx[4];
	}

	if (/*imagePreset*/ ctx[11] !== void 0) {
		modal_props.imagePreset = /*imagePreset*/ ctx[11];
	}

	if (/*closeButton*/ ctx[12] !== void 0) {
		modal_props.closeButton = /*closeButton*/ ctx[12];
	}

	modal = new Index({ props: modal_props });
	binding_callbacks.push(() => bind(modal, 'modalClasses', modal_modalClasses_binding));
	binding_callbacks.push(() => bind(modal, 'modalStyle', modal_modalStyle_binding));
	binding_callbacks.push(() => bind(modal, 'transitionDuration', modal_transitionDuration_binding));
	binding_callbacks.push(() => bind(modal, 'image', modal_image_binding));
	binding_callbacks.push(() => bind(modal, 'protect', modal_protect_binding));
	binding_callbacks.push(() => bind(modal, 'portrait', modal_portrait_binding));
	binding_callbacks.push(() => bind(modal, 'title', modal_title_binding));
	binding_callbacks.push(() => bind(modal, 'description', modal_description_binding));
	binding_callbacks.push(() => bind(modal, 'gallery', modal_gallery_binding));
	binding_callbacks.push(() => bind(modal, 'imagePreset', modal_imagePreset_binding));
	binding_callbacks.push(() => bind(modal, 'closeButton', modal_closeButton_binding));
	modal.$on("close", /*close*/ ctx[16]);
	modal.$on("topModalClick", /*coverClick*/ ctx[17]);
	modal.$on("modalClick", /*modalClick*/ ctx[18]);

	return {
		c() {
			create_component(modal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modal, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const modal_changes = {};

			if (dirty[0] & /*thumbnail, imagePreset, $$slots, gallery*/ 542736 | dirty[1] & /*$$scope*/ 64) {
				modal_changes.$$scope = { dirty, ctx };
			}

			if (!updating_modalClasses && dirty[0] & /*modalClasses*/ 4) {
				updating_modalClasses = true;
				modal_changes.modalClasses = /*modalClasses*/ ctx[2];
				add_flush_callback(() => updating_modalClasses = false);
			}

			if (!updating_modalStyle && dirty[0] & /*modalStyle*/ 8) {
				updating_modalStyle = true;
				modal_changes.modalStyle = /*modalStyle*/ ctx[3];
				add_flush_callback(() => updating_modalStyle = false);
			}

			if (!updating_transitionDuration && dirty[0] & /*transitionDuration*/ 128) {
				updating_transitionDuration = true;
				modal_changes.transitionDuration = /*transitionDuration*/ ctx[7];
				add_flush_callback(() => updating_transitionDuration = false);
			}

			if (!updating_image && dirty[0] & /*image*/ 512) {
				updating_image = true;
				modal_changes.image = /*image*/ ctx[9];
				add_flush_callback(() => updating_image = false);
			}

			if (!updating_protect && dirty[0] & /*protect*/ 256) {
				updating_protect = true;
				modal_changes.protect = /*protect*/ ctx[8];
				add_flush_callback(() => updating_protect = false);
			}

			if (!updating_portrait && dirty[0] & /*portrait*/ 1024) {
				updating_portrait = true;
				modal_changes.portrait = /*portrait*/ ctx[10];
				add_flush_callback(() => updating_portrait = false);
			}

			if (!updating_title && dirty[0] & /*title*/ 32) {
				updating_title = true;
				modal_changes.title = /*title*/ ctx[5];
				add_flush_callback(() => updating_title = false);
			}

			if (!updating_description && dirty[0] & /*description*/ 64) {
				updating_description = true;
				modal_changes.description = /*description*/ ctx[6];
				add_flush_callback(() => updating_description = false);
			}

			if (!updating_gallery && dirty[0] & /*gallery*/ 16) {
				updating_gallery = true;
				modal_changes.gallery = /*gallery*/ ctx[4];
				add_flush_callback(() => updating_gallery = false);
			}

			if (!updating_imagePreset && dirty[0] & /*imagePreset*/ 2048) {
				updating_imagePreset = true;
				modal_changes.imagePreset = /*imagePreset*/ ctx[11];
				add_flush_callback(() => updating_imagePreset = false);
			}

			if (!updating_closeButton && dirty[0] & /*closeButton*/ 4096) {
				updating_closeButton = true;
				modal_changes.closeButton = /*closeButton*/ ctx[12];
				add_flush_callback(() => updating_closeButton = false);
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modal, detaching);
		}
	};
}

function create_fragment$9(ctx) {
	let thumbnail_1;
	let updating_thumbnailClasses;
	let updating_thumbnailStyle;
	let updating_protect;
	let t;
	let if_block_anchor;
	let current;

	function thumbnail_1_thumbnailClasses_binding(value) {
		/*thumbnail_1_thumbnailClasses_binding*/ ctx[23](value);
	}

	function thumbnail_1_thumbnailStyle_binding(value) {
		/*thumbnail_1_thumbnailStyle_binding*/ ctx[24](value);
	}

	function thumbnail_1_protect_binding(value) {
		/*thumbnail_1_protect_binding*/ ctx[25](value);
	}

	let thumbnail_1_props = {
		$$slots: { default: [create_default_slot_3] },
		$$scope: { ctx }
	};

	if (/*thumbnailClasses*/ ctx[0] !== void 0) {
		thumbnail_1_props.thumbnailClasses = /*thumbnailClasses*/ ctx[0];
	}

	if (/*thumbnailStyle*/ ctx[1] !== void 0) {
		thumbnail_1_props.thumbnailStyle = /*thumbnailStyle*/ ctx[1];
	}

	if (/*protect*/ ctx[8] !== void 0) {
		thumbnail_1_props.protect = /*protect*/ ctx[8];
	}

	thumbnail_1 = new LightboxThumbnail({ props: thumbnail_1_props });
	binding_callbacks.push(() => bind(thumbnail_1, 'thumbnailClasses', thumbnail_1_thumbnailClasses_binding));
	binding_callbacks.push(() => bind(thumbnail_1, 'thumbnailStyle', thumbnail_1_thumbnailStyle_binding));
	binding_callbacks.push(() => bind(thumbnail_1, 'protect', thumbnail_1_protect_binding));
	thumbnail_1.$on("click", /*toggle*/ ctx[15]);
	let if_block = /*isVisible*/ ctx[13] && create_if_block$3(ctx);

	return {
		c() {
			create_component(thumbnail_1.$$.fragment);
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(thumbnail_1, target, anchor);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const thumbnail_1_changes = {};

			if (dirty[0] & /*thumbnail, gallery*/ 16400 | dirty[1] & /*$$scope*/ 64) {
				thumbnail_1_changes.$$scope = { dirty, ctx };
			}

			if (!updating_thumbnailClasses && dirty[0] & /*thumbnailClasses*/ 1) {
				updating_thumbnailClasses = true;
				thumbnail_1_changes.thumbnailClasses = /*thumbnailClasses*/ ctx[0];
				add_flush_callback(() => updating_thumbnailClasses = false);
			}

			if (!updating_thumbnailStyle && dirty[0] & /*thumbnailStyle*/ 2) {
				updating_thumbnailStyle = true;
				thumbnail_1_changes.thumbnailStyle = /*thumbnailStyle*/ ctx[1];
				add_flush_callback(() => updating_thumbnailStyle = false);
			}

			if (!updating_protect && dirty[0] & /*protect*/ 256) {
				updating_protect = true;
				thumbnail_1_changes.protect = /*protect*/ ctx[8];
				add_flush_callback(() => updating_protect = false);
			}

			thumbnail_1.$set(thumbnail_1_changes);

			if (/*isVisible*/ ctx[13]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*isVisible*/ 8192) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$3(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(thumbnail_1.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(thumbnail_1.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			destroy_component(thumbnail_1, detaching);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$9($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	const $$slots = compute_slots(slots);
	let { thumbnailClasses = '' } = $$props;
	let { thumbnailStyle = '' } = $$props;
	let { modalClasses = '' } = $$props;
	let { modalStyle = '' } = $$props;
	let { gallery = false } = $$props;
	let { title = '' } = $$props;
	let { description = '' } = $$props;
	let { transitionDuration = 500 } = $$props;
	let { protect = false } = $$props;
	let { image = {} } = $$props;
	let { portrait = false } = $$props;
	let { noScroll = true } = $$props;
	let { thumbnail = false } = $$props;
	let { imagePreset = false } = $$props;
	let { clickToClose = false } = $$props;
	let { closeButton = true } = $$props;
	let { isVisible = false } = $$props;
	let modalClicked = false;

	const toggle = () => {
		$$invalidate(13, isVisible = !isVisible);
		toggleScroll();
	};

	const close = () => {
		$$invalidate(13, isVisible = false);
		toggleScroll();
	};

	const coverClick = () => {
		// console.log('coverClick')
		if (!modalClicked || clickToClose) {
			close();
		}

		modalClicked = false;
	};

	const modalClick = () => {
		// console.log('modalClick')
		modalClicked = true;
	};

	let toggleScroll = () => {
		
	};

	onMount(() => {
		let defaultOverflow = document.body.style.overflow;

		toggleScroll = () => {
			if (noScroll) {
				if (isVisible) {
					document.body.style.overflow = 'hidden';
				} else {
					document.body.style.overflow = defaultOverflow;
				}
			}
		};
	});

	function thumbnail_1_thumbnailClasses_binding(value) {
		thumbnailClasses = value;
		$$invalidate(0, thumbnailClasses);
	}

	function thumbnail_1_thumbnailStyle_binding(value) {
		thumbnailStyle = value;
		$$invalidate(1, thumbnailStyle);
	}

	function thumbnail_1_protect_binding(value) {
		protect = value;
		$$invalidate(8, protect);
	}

	function modal_modalClasses_binding(value) {
		modalClasses = value;
		$$invalidate(2, modalClasses);
	}

	function modal_modalStyle_binding(value) {
		modalStyle = value;
		$$invalidate(3, modalStyle);
	}

	function modal_transitionDuration_binding(value) {
		transitionDuration = value;
		$$invalidate(7, transitionDuration);
	}

	function modal_image_binding(value) {
		image = value;
		$$invalidate(9, image);
	}

	function modal_protect_binding(value) {
		protect = value;
		$$invalidate(8, protect);
	}

	function modal_portrait_binding(value) {
		portrait = value;
		$$invalidate(10, portrait);
	}

	function modal_title_binding(value) {
		title = value;
		$$invalidate(5, title);
	}

	function modal_description_binding(value) {
		description = value;
		$$invalidate(6, description);
	}

	function modal_gallery_binding(value) {
		gallery = value;
		$$invalidate(4, gallery);
	}

	function modal_imagePreset_binding(value) {
		imagePreset = value;
		$$invalidate(11, imagePreset);
	}

	function modal_closeButton_binding(value) {
		closeButton = value;
		$$invalidate(12, closeButton);
	}

	$$self.$$set = $$props => {
		if ('thumbnailClasses' in $$props) $$invalidate(0, thumbnailClasses = $$props.thumbnailClasses);
		if ('thumbnailStyle' in $$props) $$invalidate(1, thumbnailStyle = $$props.thumbnailStyle);
		if ('modalClasses' in $$props) $$invalidate(2, modalClasses = $$props.modalClasses);
		if ('modalStyle' in $$props) $$invalidate(3, modalStyle = $$props.modalStyle);
		if ('gallery' in $$props) $$invalidate(4, gallery = $$props.gallery);
		if ('title' in $$props) $$invalidate(5, title = $$props.title);
		if ('description' in $$props) $$invalidate(6, description = $$props.description);
		if ('transitionDuration' in $$props) $$invalidate(7, transitionDuration = $$props.transitionDuration);
		if ('protect' in $$props) $$invalidate(8, protect = $$props.protect);
		if ('image' in $$props) $$invalidate(9, image = $$props.image);
		if ('portrait' in $$props) $$invalidate(10, portrait = $$props.portrait);
		if ('noScroll' in $$props) $$invalidate(20, noScroll = $$props.noScroll);
		if ('thumbnail' in $$props) $$invalidate(14, thumbnail = $$props.thumbnail);
		if ('imagePreset' in $$props) $$invalidate(11, imagePreset = $$props.imagePreset);
		if ('clickToClose' in $$props) $$invalidate(21, clickToClose = $$props.clickToClose);
		if ('closeButton' in $$props) $$invalidate(12, closeButton = $$props.closeButton);
		if ('isVisible' in $$props) $$invalidate(13, isVisible = $$props.isVisible);
		if ('$$scope' in $$props) $$invalidate(37, $$scope = $$props.$$scope);
	};

	return [
		thumbnailClasses,
		thumbnailStyle,
		modalClasses,
		modalStyle,
		gallery,
		title,
		description,
		transitionDuration,
		protect,
		image,
		portrait,
		imagePreset,
		closeButton,
		isVisible,
		thumbnail,
		toggle,
		close,
		coverClick,
		modalClick,
		$$slots,
		noScroll,
		clickToClose,
		slots,
		thumbnail_1_thumbnailClasses_binding,
		thumbnail_1_thumbnailStyle_binding,
		thumbnail_1_protect_binding,
		modal_modalClasses_binding,
		modal_modalStyle_binding,
		modal_transitionDuration_binding,
		modal_image_binding,
		modal_protect_binding,
		modal_portrait_binding,
		modal_title_binding,
		modal_description_binding,
		modal_gallery_binding,
		modal_imagePreset_binding,
		modal_closeButton_binding,
		$$scope
	];
}

class Lightbox extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$9,
			create_fragment$9,
			safe_not_equal,
			{
				thumbnailClasses: 0,
				thumbnailStyle: 1,
				modalClasses: 2,
				modalStyle: 3,
				gallery: 4,
				title: 5,
				description: 6,
				transitionDuration: 7,
				protect: 8,
				image: 9,
				portrait: 10,
				noScroll: 20,
				thumbnail: 14,
				imagePreset: 11,
				clickToClose: 21,
				closeButton: 12,
				isVisible: 13
			},
			null,
			[-1, -1]
		);
	}
}

export { Lightbox };
