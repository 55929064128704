let api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcF9pbnRlcmlvckB3aW5nbWFjaHQuZWUiLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6OCwicm9sZXMiOiJ1c2VyIiwiaWF0IjoxNjQ4MDI2NjY2fQ.o8thq4oXWdrlVaOzEBtp9wlfrlySwwX0ecXVXA-gllY'

export function api_url() {
    return 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/'
}

export function api_get(url) {
    return fetch(api_url()+url, {  headers: {'xc-auth': api_key }})
}

export function api_post(url, body) {
    return fetch(api_url()+url, {  method: 'POST', headers: {'xc-auth': api_key, 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
}

export function api_put(url, body) {
    return fetch(api_url()+url, {  method: 'PUT', headers: {'xc-auth': api_key, 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
}