/* Garantii.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out
} from "./_snowpack/pkg/svelte/internal.js";

import { onMount } from './_snowpack/pkg/svelte.js';
import { user } from "./stores.js";
import { user_address } from "./stores.js";
import { is_DEV } from "./stores.js";
import { Router, Route, Link } from "./_snowpack/pkg/svelte-navigator.js";
import { useParams, useNavigate, useLocation } from "./_snowpack/pkg/svelte-navigator.js";

//https://github.com/Hejtmus/svelte-lightbox
import { Lightbox } from './_snowpack/pkg/svelte-lightbox.js';

import Time from "./_snowpack/pkg/svelte-time.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	child_ctx[28] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[29] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[32] = list[i];
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	child_ctx[28] = i;
	return child_ctx;
}

function get_each_context_4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[29] = list[i];
	return child_ctx;
}

// (177:20) {#if row["typ"] == typ }
function create_if_block_2(ctx) {
	let div3;
	let div0;
	let label0;
	let t0_value = /*calc_row_num*/ ctx[7](/*i*/ ctx[28]) + "";
	let t0;
	let t1;
	let div1;
	let input0;
	let input0_value_value;
	let t2;
	let div2;
	let label1;
	let input1;
	let t3;
	let span2;
	let t6;
	let mounted;
	let dispose;

	function change_handler(...args) {
		return /*change_handler*/ ctx[13](/*i*/ ctx[28], ...args);
	}

	function change_handler_1(...args) {
		return /*change_handler_1*/ ctx[14](/*i*/ ctx[28], ...args);
	}

	let each_value_4 = /*row*/ ctx[26].files;
	let each_blocks = [];

	for (let i = 0; i < each_value_4.length; i += 1) {
		each_blocks[i] = create_each_block_4(get_each_context_4(ctx, each_value_4, i));
	}

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			label0 = element("label");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			input0 = element("input");
			t2 = space();
			div2 = element("div");
			label1 = element("label");
			input1 = element("input");
			t3 = space();
			span2 = element("span");

			span2.innerHTML = `<span class="file-icon"><i class="mdi mdi-tray-arrow-up"></i></span> 
                                        <span class="file-label">Lisa pilt</span>`;

			t6 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(label0, "class", "label");
			attr(div0, "class", "control");
			attr(input0, "class", "input");
			attr(input0, "type", "text");
			attr(input0, "placeholder", "Kirjeldus");
			input0.value = input0_value_value = /*row*/ ctx[26].comment;
			attr(div1, "class", "control");
			attr(input1, "class", "file-input");
			attr(input1, "type", "file");
			attr(span2, "class", "file-cta");
			attr(label1, "class", "file-label");
			attr(div2, "class", "control");
			attr(div3, "class", "field is-grouped");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			append(div0, label0);
			append(label0, t0);
			append(div3, t1);
			append(div3, div1);
			append(div1, input0);
			append(div3, t2);
			append(div3, div2);
			append(div2, label1);
			append(label1, input1);
			append(label1, t3);
			append(label1, span2);
			append(div3, t6);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div3, null);
			}

			if (!mounted) {
				dispose = [
					listen(input0, "change", change_handler),
					listen(input1, "change", change_handler_1)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*garantii_data*/ 2 && input0_value_value !== (input0_value_value = /*row*/ ctx[26].comment) && input0.value !== input0_value_value) {
				input0.value = input0_value_value;
			}

			if (dirty[0] & /*handleFileDelete, garantii_data, filedata*/ 522) {
				each_value_4 = /*row*/ ctx[26].files;
				let i;

				for (i = 0; i < each_value_4.length; i += 1) {
					const child_ctx = get_each_context_4(ctx, each_value_4, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_4(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div3, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_4.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div3);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (200:28) {#each row.files as file}
function create_each_block_4(ctx) {
	let div0;
	let img;
	let img_src_value;
	let t;
	let div1;
	let button;
	let mounted;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[15](/*i*/ ctx[28], /*file*/ ctx[29], ...args);
	}

	return {
		c() {
			div0 = element("div");
			img = element("img");
			t = space();
			div1 = element("div");
			button = element("button");
			button.innerHTML = `<span class="mdi mdi-trash-can-outline"></span>`;
			if (!src_url_equal(img.src, img_src_value = /*filedata*/ ctx[3][/*file*/ ctx[29]])) attr(img, "src", img_src_value);
			attr(div0, "class", "control");
			attr(button, "class", "button is-secondary is-small");
			attr(div1, "class", "control");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, img);
			insert(target, t, anchor);
			insert(target, div1, anchor);
			append(div1, button);

			if (!mounted) {
				dispose = listen(button, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*filedata, garantii_data*/ 10 && !src_url_equal(img.src, img_src_value = /*filedata*/ ctx[3][/*file*/ ctx[29]])) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t);
			if (detaching) detach(div1);
			mounted = false;
			dispose();
		}
	};
}

// (176:16) {#each garantii_data.rows as row, i}
function create_each_block_3(ctx) {
	let if_block_anchor;
	let if_block = /*row*/ ctx[26]["typ"] == /*typ*/ ctx[32] && create_if_block_2(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*row*/ ctx[26]["typ"] == /*typ*/ ctx[32]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (171:0) {#each types as typ}
function create_each_block_2(ctx) {
	let h3;
	let t0_value = /*typ*/ ctx[32] + "";
	let t0;
	let t1;
	let div3;
	let div2;
	let article;
	let t2;
	let div1;
	let div0;
	let button;
	let mounted;
	let dispose;
	let each_value_3 = /*garantii_data*/ ctx[1].rows;
	let each_blocks = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	function click_handler_1(...args) {
		return /*click_handler_1*/ ctx[16](/*typ*/ ctx[32], ...args);
	}

	return {
		c() {
			h3 = element("h3");
			t0 = text(t0_value);
			t1 = space();
			div3 = element("div");
			div2 = element("div");
			article = element("article");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			div1 = element("div");
			div0 = element("div");
			button = element("button");
			button.textContent = "Lisa rida";
			attr(h3, "class", "subtitle");
			attr(button, "class", "button is-secondary is-small");
			attr(div0, "class", "control");
			attr(div1, "class", "field is-grouped");
			attr(article, "class", "tile is-child");
			attr(div2, "class", "tile is-parent");
			attr(div3, "class", "tile is-ancestor");
		},
		m(target, anchor) {
			insert(target, h3, anchor);
			append(h3, t0);
			insert(target, t1, anchor);
			insert(target, div3, anchor);
			append(div3, div2);
			append(div2, article);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(article, null);
			}

			append(article, t2);
			append(article, div1);
			append(div1, div0);
			append(div0, button);

			if (!mounted) {
				dispose = listen(button, "click", click_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*garantii_data, handleFileDelete, filedata, handleFileUpload, handleChangeComment, calc_row_num, types*/ 1994) {
				each_value_3 = /*garantii_data*/ ctx[1].rows;
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_3(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(article, t2);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_3.length;
			}
		},
		d(detaching) {
			if (detaching) detach(h3);
			if (detaching) detach(t1);
			if (detaching) detach(div3);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

// (233:0) {#if garantii_data_prev.rows.length > 0 }
function create_if_block_1(ctx) {
	let h3;
	let t1;
	let div;
	let table;
	let tbody;
	let current;
	let each_value = /*garantii_data_prev*/ ctx[2].rows;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			h3 = element("h3");
			h3.textContent = "Varasemad tööd";
			t1 = space();
			div = element("div");
			table = element("table");
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(h3, "class", "subtitle");
			attr(table, "class", "table");
			attr(div, "class", "table-container");
		},
		m(target, anchor) {
			insert(target, h3, anchor);
			insert(target, t1, anchor);
			insert(target, div, anchor);
			append(div, table);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*garantii_data_prev, filedata*/ 12) {
				each_value = /*garantii_data_prev*/ ctx[2].rows;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(tbody, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(h3);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (247:24) {#each row.files as file}
function create_each_block_1(ctx) {
	let div;
	let img;
	let img_src_value;
	let t;

	return {
		c() {
			div = element("div");
			img = element("img");
			t = space();
			if (!src_url_equal(img.src, img_src_value = /*filedata*/ ctx[3][/*file*/ ctx[29]])) attr(img, "src", img_src_value);
			attr(div, "class", "control");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, img);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*filedata, garantii_data_prev*/ 12 && !src_url_equal(img.src, img_src_value = /*filedata*/ ctx[3][/*file*/ ctx[29]])) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (239:12) {#each garantii_data_prev.rows as row, i}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*i*/ ctx[28] + 1 + "";
	let t0;
	let t1;
	let td1;
	let time;
	let t2;
	let td2;
	let t3_value = /*row*/ ctx[26].typ + "";
	let t3;
	let t4;
	let td3;
	let t5_value = /*row*/ ctx[26].comment + "";
	let t5;
	let t6;
	let td4;
	let div;
	let t7;
	let current;

	time = new Time({
			props: {
				timestamp: new Date(/*row*/ ctx[26].UpdatedAt),
				format: "DD.MM.YYYY"
			}
		});

	let each_value_1 = /*row*/ ctx[26].files;
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			create_component(time.$$.fragment);
			t2 = space();
			td2 = element("td");
			t3 = text(t3_value);
			t4 = space();
			td3 = element("td");
			t5 = text(t5_value);
			t6 = space();
			td4 = element("td");
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t7 = space();
			attr(div, "class", "field is-grouped");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			mount_component(time, td1, null);
			append(tr, t2);
			append(tr, td2);
			append(td2, t3);
			append(tr, t4);
			append(tr, td3);
			append(td3, t5);
			append(tr, t6);
			append(tr, td4);
			append(td4, div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			append(tr, t7);
			current = true;
		},
		p(ctx, dirty) {
			const time_changes = {};
			if (dirty[0] & /*garantii_data_prev*/ 4) time_changes.timestamp = new Date(/*row*/ ctx[26].UpdatedAt);
			time.$set(time_changes);
			if ((!current || dirty[0] & /*garantii_data_prev*/ 4) && t3_value !== (t3_value = /*row*/ ctx[26].typ + "")) set_data(t3, t3_value);
			if ((!current || dirty[0] & /*garantii_data_prev*/ 4) && t5_value !== (t5_value = /*row*/ ctx[26].comment + "")) set_data(t5, t5_value);

			if (dirty[0] & /*filedata, garantii_data_prev*/ 12) {
				each_value_1 = /*row*/ ctx[26].files;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		i(local) {
			if (current) return;
			transition_in(time.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(time.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(tr);
			destroy_component(time);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (261:0) {#if ($is_DEV===true) }
function create_if_block(ctx) {
	let pre;
	let t0_value = JSON.stringify(/*garantii_data*/ ctx[1], null, 4) + "";
	let t0;
	let t1;
	let t2_value = JSON.stringify(/*garantii_data_prev*/ ctx[2], null, 4) + "";
	let t2;

	return {
		c() {
			pre = element("pre");
			t0 = text(t0_value);
			t1 = text("\n    ");
			t2 = text(t2_value);
		},
		m(target, anchor) {
			insert(target, pre, anchor);
			append(pre, t0);
			append(pre, t1);
			append(pre, t2);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*garantii_data*/ 2 && t0_value !== (t0_value = JSON.stringify(/*garantii_data*/ ctx[1], null, 4) + "")) set_data(t0, t0_value);
			if (dirty[0] & /*garantii_data_prev*/ 4 && t2_value !== (t2_value = JSON.stringify(/*garantii_data_prev*/ ctx[2], null, 4) + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(pre);
		}
	};
}

function create_fragment(ctx) {
	let h2;
	let t0;
	let t1_value = /*garantii*/ ctx[0].addr + "";
	let t1;
	let t2;
	let hr0;
	let t3;
	let p;
	let t5;
	let t6;
	let div1;
	let div0;
	let button;
	let t8;
	let hr1;
	let t9;
	let t10;
	let if_block1_anchor;
	let current;
	let mounted;
	let dispose;
	let each_value_2 = /*types*/ ctx[6];
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let if_block0 = /*garantii_data_prev*/ ctx[2].rows.length > 0 && create_if_block_1(ctx);
	let if_block1 = /*$is_DEV*/ ctx[4] === true && create_if_block(ctx);

	return {
		c() {
			h2 = element("h2");
			t0 = text("Garantiitööd. ");
			t1 = text(t1_value);
			t2 = space();
			hr0 = element("hr");
			t3 = space();
			p = element("p");
			p.textContent = "Palun teavita oma garantii juhtumist valides kategooriate alt sobiv. Kirjelda ning lisa pilte.";
			t5 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t6 = space();
			div1 = element("div");
			div0 = element("div");
			button = element("button");
			button.textContent = "Saada";
			t8 = space();
			hr1 = element("hr");
			t9 = space();
			if (if_block0) if_block0.c();
			t10 = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
			attr(h2, "class", "title");
			attr(button, "class", "button is-primary");
			attr(div0, "class", "control");
			attr(div1, "class", "field is-grouped");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			append(h2, t0);
			append(h2, t1);
			insert(target, t2, anchor);
			insert(target, hr0, anchor);
			insert(target, t3, anchor);
			insert(target, p, anchor);
			insert(target, t5, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, t6, anchor);
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button);
			insert(target, t8, anchor);
			insert(target, hr1, anchor);
			insert(target, t9, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t10, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*handleSubmit*/ ctx[12]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if ((!current || dirty[0] & /*garantii*/ 1) && t1_value !== (t1_value = /*garantii*/ ctx[0].addr + "")) set_data(t1, t1_value);

			if (dirty[0] & /*handle_add_row, types, garantii_data, handleFileDelete, filedata, handleFileUpload, handleChangeComment, calc_row_num*/ 4042) {
				each_value_2 = /*types*/ ctx[6];
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(t6.parentNode, t6);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}

			if (/*garantii_data_prev*/ ctx[2].rows.length > 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*garantii_data_prev*/ 4) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t10.parentNode, t10);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*$is_DEV*/ ctx[4] === true) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t2);
			if (detaching) detach(hr0);
			if (detaching) detach(t3);
			if (detaching) detach(p);
			if (detaching) detach(t5);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t6);
			if (detaching) detach(div1);
			if (detaching) detach(t8);
			if (detaching) detach(hr1);
			if (detaching) detach(t9);
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t10);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
			mounted = false;
			dispose();
		}
	};
}

let api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcF9pbnRlcmlvckB3aW5nbWFjaHQuZWUiLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6OCwicm9sZXMiOiJ1c2VyIiwiaWF0IjoxNjQ4MDI2NjY2fQ.o8thq4oXWdrlVaOzEBtp9wlfrlySwwX0ecXVXA-gllY';
let entity = 'garantii';

function instance($$self, $$props, $$invalidate) {
	let $location;
	let $user_address;
	let $user;
	let $is_DEV;
	component_subscribe($$self, user_address, $$value => $$invalidate(18, $user_address = $$value));
	component_subscribe($$self, user, $$value => $$invalidate(19, $user = $$value));
	component_subscribe($$self, is_DEV, $$value => $$invalidate(4, $is_DEV = $$value));
	const navigate = useNavigate();
	const location = useLocation();
	component_subscribe($$self, location, value => $$invalidate(17, $location = value));
	const params = useParams();
	let garantii = {};

	let garantii_data = {
		"rows": [],
		"rows_siseviimistlus": [],
		"rows_valistood": [],
		"rows_elekter": [],
		"rows_sanitaartehnika": []
	};

	let garantii_data_prev = {
		"rows": [],
		"rows_siseviimistlus": [],
		"rows_valistood": [],
		"rows_elekter": [],
		"rows_sanitaartehnika": []
	};

	let garantii_link = $user;
	const types = ["Siseviimistlus", "Välistööd", "Elekter", "Sanitaartehnika"];
	let url_get = 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/' + entity + '/?limit=100&sort=id&where=' + '(link,eq,' + garantii_link + ')';
	let url_insert = 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/' + entity;

	onMount(async () => {
		fetch(url_get, { headers: { 'xc-auth': api_key } }).then(response => response.json()).then(data => {
			$$invalidate(0, garantii = data[0]);
			set_store_value(user_address, $user_address = garantii.addr, $user_address);

			// garantii_data_prev = JSON.parse(garantii.data)
			// if (garantii_data_prev == null) garantii_data_prev = {"rows":[]}
			// if (("rows" in garantii_data_prev)==false) garantii_data_prev['rows'] = []
			data.forEach(row_data => {
				let a = JSON.parse(row_data.data);

				if (a != null && "rows" in a) {
					a["rows"].forEach(row => {
						row["UpdatedAt"] = row_data["UpdatedAt"];
						garantii_data_prev['rows'].push(row);
					});
				}
			});

			$$invalidate(2, garantii_data_prev['rows'] = garantii_data_prev['rows'].filter(row => row.comment != "" || row.files.count > 0), garantii_data_prev);

			types.forEach(typ => {
				let typ_count = garantii_data['rows'].filter(row => row['typ'] == typ);

				for (let i = 0; i < 3 - typ_count.length; i++) {
					garantii_data.rows.push({ "files": [], "comment": "", typ });
				}
			});

			garantii_data_prev.rows.forEach(row => {
				row.files.forEach(f => {
					$$invalidate(3, filedata[f] = "/img/loading.gif", filedata);
				});
			});

			$$invalidate(3, filedata);
			$$invalidate(1, garantii_data);
			$$invalidate(2, garantii_data_prev);
			refreshFiles();
		});
	});

	function calc_row_num(i) {
		let ret = 1;
		let t = garantii_data.rows[i]["typ"];

		for (let j = 0; j < i; j++) {
			if (t == garantii_data.rows[j]["typ"]) {
				ret++;
			}
		}

		return ret;
	}

	function handleFileUpload(e, i) {
		let image = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(image);

		reader.onload = e => {
			let upl_filedata = e.target.result;
			console.log(e.target);

			fetch("https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/file", {
				method: 'POST',
				headers: {
					'xc-auth': api_key,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ 'filedata': "" + upl_filedata })
			}).then(response => response.json()).then(data => {
				console.log(data);
				garantii_data.rows[i].files.push(data.Id);
				$$invalidate(1, garantii_data);
				$$invalidate(3, filedata[data.Id] = data.filedata, filedata);
			});
		};
	}

	let filedata = [];

	function refreshFiles() {
		Array.from(Object.keys(filedata)).forEach(f => {
			fetch("https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/file/" + f, { headers: { 'xc-auth': api_key } }).then(response => response.json()).then(data => {
				$$invalidate(3, filedata[data.Id] = data.filedata, filedata);
				$$invalidate(3, filedata);
			});
		}); //garantii_data = garantii_data
	}

	function handleFileDelete(e, row, file) {
		var lst = garantii_data.rows[row].files;
		var i = lst.indexOf(file);

		if (i !== -1) {
			lst.splice(i, 1);
		}

		$$invalidate(1, garantii_data);
	}

	function handleChangeComment(e, i) {
		$$invalidate(1, garantii_data.rows[i].comment = e.target.value, garantii_data);
		$$invalidate(1, garantii_data);
		console.log(garantii_data);
	}

	function handle_add_row(t) {
		console.log(t);
		garantii_data.rows.push({ "files": [], "comment": "", "typ": t });
		$$invalidate(1, garantii_data);
	}

	function handleSubmit() {
		delete garantii.Id;
		delete garantii.UpdatedAt;
		$$invalidate(0, garantii.data = JSON.stringify(garantii_data), garantii);

		fetch(url_insert, {
			method: 'POST',
			headers: {
				'xc-auth': api_key,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(garantii)
		}).then(response => response.json()).then(data => {
			//alert(JSON.stringify(data))	
			navigate("/thankyou", {
				state: { from: $location.pathname },
				replace: true
			});
		});
	}

	const change_handler = (i, e) => handleChangeComment(e, i);

	const change_handler_1 = (i, e) => {
		handleFileUpload(e, i);
	};

	const click_handler = (i, file, e) => {
		handleFileDelete(e, i, file);
	};

	const click_handler_1 = (typ, e) => {
		handle_add_row(typ);
	};

	return [
		garantii,
		garantii_data,
		garantii_data_prev,
		filedata,
		$is_DEV,
		location,
		types,
		calc_row_num,
		handleFileUpload,
		handleFileDelete,
		handleChangeComment,
		handle_add_row,
		handleSubmit,
		change_handler,
		change_handler_1,
		click_handler,
		click_handler_1
	];
}

class Garantii extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, null, [-1, -1]);
	}
}

export default Garantii;