/* Login.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_input_value,
	set_store_value,
	space
} from "./_snowpack/pkg/svelte/internal.js";

import { useNavigate, useLocation } from "./_snowpack/pkg/svelte-navigator.js";
import { user } from "./stores.js";
import { is_DEV } from "./stores.js";

function create_if_block(ctx) {
	let a0;
	let t1;
	let br;
	let t2;
	let a1;

	return {
		c() {
			a0 = element("a");
			a0.textContent = "TEST Login";
			t1 = space();
			br = element("br");
			t2 = space();
			a1 = element("a");
			a1.textContent = "TEST Login - Andrekse 39-2";
			attr(a0, "href", "http://dev.local:9090/?link=786bdd99-71be-47bf-8ca8-c4a3d94d095d");
			attr(a1, "href", "http://dev.local:9090/?link=e9020a83-e1fd-4bb0-9a31-ab9b81ef553b");
		},
		m(target, anchor) {
			insert(target, a0, anchor);
			insert(target, t1, anchor);
			insert(target, br, anchor);
			insert(target, t2, anchor);
			insert(target, a1, anchor);
		},
		d(detaching) {
			if (detaching) detach(a0);
			if (detaching) detach(t1);
			if (detaching) detach(br);
			if (detaching) detach(t2);
			if (detaching) detach(a1);
		}
	};
}

function create_fragment(ctx) {
	let h2;
	let t1;
	let hr;
	let t2;
	let form;
	let div1;
	let div0;
	let input0;
	let t3;
	let div3;
	let div2;
	let input1;
	let t4;
	let div5;
	let t6;
	let if_block_anchor;
	let mounted;
	let dispose;
	let if_block = /*$is_DEV*/ ctx[2] === true && create_if_block(ctx);

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Login";
			t1 = space();
			hr = element("hr");
			t2 = space();
			form = element("form");
			div1 = element("div");
			div0 = element("div");
			input0 = element("input");
			t3 = space();
			div3 = element("div");
			div2 = element("div");
			input1 = element("input");
			t4 = space();
			div5 = element("div");
			div5.innerHTML = `<div class="control"><button type="submit" class="button is-primary">Login</button></div>`;
			t6 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(h2, "class", "subtitle");
			attr(input0, "type", "text");
			attr(input0, "name", "email");
			attr(input0, "placeholder", "Email");
			attr(input0, "class", "input");
			attr(div0, "class", "control");
			attr(div1, "class", "field");
			attr(input1, "type", "password");
			attr(input1, "name", "password");
			attr(input1, "placeholder", "Password");
			attr(input1, "class", "input");
			attr(div2, "class", "control");
			attr(div3, "class", "field");
			attr(div5, "class", "field is-grouped");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, hr, anchor);
			insert(target, t2, anchor);
			insert(target, form, anchor);
			append(form, div1);
			append(div1, div0);
			append(div0, input0);
			set_input_value(input0, /*email*/ ctx[0]);
			append(form, t3);
			append(form, div3);
			append(div3, div2);
			append(div2, input1);
			set_input_value(input1, /*password*/ ctx[1]);
			append(form, t4);
			append(form, div5);
			insert(target, t6, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);

			if (!mounted) {
				dispose = [
					listen(input0, "input", /*input0_input_handler*/ ctx[5]),
					listen(input1, "input", /*input1_input_handler*/ ctx[6]),
					listen(form, "submit", /*handleSubmit*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*email*/ 1 && input0.value !== /*email*/ ctx[0]) {
				set_input_value(input0, /*email*/ ctx[0]);
			}

			if (dirty & /*password*/ 2 && input1.value !== /*password*/ ctx[1]) {
				set_input_value(input1, /*password*/ ctx[1]);
			}

			if (/*$is_DEV*/ ctx[2] === true) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(hr);
			if (detaching) detach(t2);
			if (detaching) detach(form);
			if (detaching) detach(t6);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $location;
	let $user;
	let $is_DEV;
	component_subscribe($$self, user, $$value => $$invalidate(8, $user = $$value));
	component_subscribe($$self, is_DEV, $$value => $$invalidate(2, $is_DEV = $$value));
	const navigate = useNavigate();
	const location = useLocation();
	component_subscribe($$self, location, value => $$invalidate(7, $location = value));
	let email;
	let password;

	function handleSubmit() {
		const from = $location.state && $location.state.from || "/";
		navigate(from, { replace: true });

		fetch('https://ncdb.surflink.ee/auth/signin', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, password })
		}).then(response => response.json()).then(data => {
			console.log(data);
			let token = data['token'];
			set_store_value(user, $user = token, $user);

			navigate("./profile", {
				state: { from: $location.pathname },
				replace: true
			});
		});
	}

	function input0_input_handler() {
		email = this.value;
		$$invalidate(0, email);
	}

	function input1_input_handler() {
		password = this.value;
		$$invalidate(1, password);
	}

	return [
		email,
		password,
		$is_DEV,
		location,
		handleSubmit,
		input0_input_handler,
		input1_input_handler
	];
}

class Login extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Login;