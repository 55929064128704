/* App.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "./_snowpack/pkg/svelte/internal.js";

import { Router, Route, Link } from "./_snowpack/pkg/svelte-navigator.js";
import PrivateRoute from "./PrivateRoute.svelte.js";
import { user } from "./stores.js";
import { user_address } from "./stores.js";
import { is_DEV } from "./stores.js";
import Login from "./Login.svelte.js";
import Default from "./Default.svelte.js";
import Demo from "./Demo.svelte.js";
import Garantii from "./Garantii.svelte.js";
import Task from "./Task.svelte.js";

function create_if_block_1(ctx) {
	let link0;
	let t;
	let link1;
	let current;

	link0 = new Link({
			props: {
				class: "navbar-item",
				to: "demo",
				$$slots: { default: [create_default_slot_10] },
				$$scope: { ctx }
			}
		});

	link1 = new Link({
			props: {
				class: "navbar-item",
				to: "garantii",
				$$slots: { default: [create_default_slot_9] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link0.$$.fragment);
			t = space();
			create_component(link1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link0, target, anchor);
			insert(target, t, anchor);
			mount_component(link1, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(link0.$$.fragment, local);
			transition_in(link1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link0.$$.fragment, local);
			transition_out(link1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link0, detaching);
			if (detaching) detach(t);
			destroy_component(link1, detaching);
		}
	};
}

// (52:6) <Link class="navbar-item" to="demo">
function create_default_slot_10(ctx) {
	let t;

	return {
		c() {
			t = text("Siseviimistluse valik");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (53:6) <Link class="navbar-item" to="garantii">
function create_default_slot_9(ctx) {
	let t;

	return {
		c() {
			t = text("Garantii");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (57:2) {#if $user}
function create_if_block(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				class: "navbar-item",
				to: "profile",
				$$slots: { default: [create_default_slot_8] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const link_changes = {};

			if (dirty & /*$$scope, $user_address*/ 1028) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

// (58:2) <Link class="navbar-item" to="profile">
function create_default_slot_8(ctx) {
	let t;

	return {
		c() {
			t = text(/*$user_address*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$user_address*/ 4) set_data(t, /*$user_address*/ ctx[2]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (72:4) <Route path="login">
function create_default_slot_7(ctx) {
	let login;
	let current;
	login = new Login({});

	return {
		c() {
			create_component(login.$$.fragment);
		},
		m(target, anchor) {
			mount_component(login, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(login.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(login.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(login, detaching);
		}
	};
}

// (76:4) <PrivateRoute path="/">
function create_default_slot_6(ctx) {
	let default_1;
	let current;
	default_1 = new Default({});

	return {
		c() {
			create_component(default_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(default_1, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(default_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(default_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(default_1, detaching);
		}
	};
}

// (80:1) <PrivateRoute path="thankyou" let:location>
function create_default_slot_5(ctx) {
	let h2;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Andmed saadetud, Täname!";
			attr(h2, "class", "subtitle");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
		},
		d(detaching) {
			if (detaching) detach(h2);
		}
	};
}

// (84:4) <PrivateRoute path="profile" let:location>
function create_default_slot_4(ctx) {
	let h2;
	let t0;
	let div1;
	let div0;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			h2 = element("h2");
			t0 = space();
			div1 = element("div");
			div0 = element("div");
			button = element("button");
			button.textContent = "Logout";
			attr(h2, "class", "subtitle");
			attr(button, "class", "button");
			attr(div0, "class", "control");
			attr(div1, "class", "field is-grouped");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button);

			if (!mounted) {
				dispose = listen(button, "click", /*handleLogout*/ ctx[3]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
			mounted = false;
			dispose();
		}
	};
}

// (97:1) <PrivateRoute path="demo" let:location let:params>
function create_default_slot_3(ctx) {
	let demo;
	let current;
	demo = new Demo({});

	return {
		c() {
			create_component(demo.$$.fragment);
		},
		m(target, anchor) {
			mount_component(demo, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(demo.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(demo.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(demo, detaching);
		}
	};
}

// (101:1) <PrivateRoute path="garantii" let:location let:params>
function create_default_slot_2(ctx) {
	let garantii;
	let current;
	garantii = new Garantii({});

	return {
		c() {
			create_component(garantii.$$.fragment);
		},
		m(target, anchor) {
			mount_component(garantii, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(garantii.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(garantii.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(garantii, detaching);
		}
	};
}

// (105:1) <PrivateRoute path="task/:id" let:location let:params>
function create_default_slot_1(ctx) {
	let task;
	let current;
	task = new Task({});

	return {
		c() {
			create_component(task.$$.fragment);
		},
		m(target, anchor) {
			mount_component(task, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(task.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(task.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(task, detaching);
		}
	};
}

// (34:0) <Router>
function create_default_slot(ctx) {
	let header;
	let nav;
	let div0;
	let a0;
	let t0;
	let a1;
	let t3;
	let div3;
	let div1;
	let t4;
	let div2;
	let t5;
	let main;
	let route;
	let t6;
	let privateroute0;
	let t7;
	let privateroute1;
	let t8;
	let privateroute2;
	let t9;
	let privateroute3;
	let t10;
	let privateroute4;
	let t11;
	let privateroute5;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*$user*/ ctx[1] && create_if_block_1(ctx);
	let if_block1 = /*$user*/ ctx[1] && create_if_block(ctx);

	route = new Route({
			props: {
				path: "login",
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			}
		});

	privateroute0 = new PrivateRoute({
			props: {
				path: "/",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	privateroute1 = new PrivateRoute({
			props: {
				path: "thankyou",
				$$slots: {
					default: [
						create_default_slot_5,
						({ location }) => ({ 8: location }),
						({ location }) => location ? 256 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	privateroute2 = new PrivateRoute({
			props: {
				path: "profile",
				$$slots: {
					default: [
						create_default_slot_4,
						({ location }) => ({ 8: location }),
						({ location }) => location ? 256 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	privateroute3 = new PrivateRoute({
			props: {
				path: "demo",
				$$slots: {
					default: [
						create_default_slot_3,
						({ location, params }) => ({ 8: location, 9: params }),
						({ location, params }) => (location ? 256 : 0) | (params ? 512 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	privateroute4 = new PrivateRoute({
			props: {
				path: "garantii",
				$$slots: {
					default: [
						create_default_slot_2,
						({ location, params }) => ({ 8: location, 9: params }),
						({ location, params }) => (location ? 256 : 0) | (params ? 512 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	privateroute5 = new PrivateRoute({
			props: {
				path: "task/:id",
				$$slots: {
					default: [
						create_default_slot_1,
						({ location, params }) => ({ 8: location, 9: params }),
						({ location, params }) => (location ? 256 : 0) | (params ? 512 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			header = element("header");
			nav = element("nav");
			div0 = element("div");
			a0 = element("a");
			a0.innerHTML = `<img src="/img/logo.png"/>`;
			t0 = space();
			a1 = element("a");

			a1.innerHTML = `<span aria-hidden="true"></span> 
		  <span aria-hidden="true"></span> 
		  <span aria-hidden="true"></span>`;

			t3 = space();
			div3 = element("div");
			div1 = element("div");
			if (if_block0) if_block0.c();
			t4 = space();
			div2 = element("div");
			if (if_block1) if_block1.c();
			t5 = space();
			main = element("main");
			create_component(route.$$.fragment);
			t6 = space();
			create_component(privateroute0.$$.fragment);
			t7 = space();
			create_component(privateroute1.$$.fragment);
			t8 = space();
			create_component(privateroute2.$$.fragment);
			t9 = space();
			create_component(privateroute3.$$.fragment);
			t10 = space();
			create_component(privateroute4.$$.fragment);
			t11 = space();
			create_component(privateroute5.$$.fragment);
			attr(a0, "class", "navbar-item");
			attr(a0, "href", "#");
			attr(a1, "role", "button");
			attr(a1, "class", "navbar-burger");
			attr(a1, "aria-label", "menu");
			attr(a1, "aria-expanded", "false");
			attr(a1, "data-target", "navbarBasicExample");
			attr(div0, "class", "navbar-brand");
			attr(div1, "class", "navbar-start");
			attr(div2, "class", "navbar-end");
			attr(div3, "id", "navbarBasicExample");
			attr(div3, "class", "navbar-menu");
			toggle_class(div3, "is-active", /*navbar_active*/ ctx[0]);
			attr(nav, "class", "navbar");
			attr(nav, "role", "navigation");
			attr(nav, "aria-label", "main navigation");
			attr(nav, "id", "navbar");
			attr(main, "class", "container is-fluid");
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, nav);
			append(nav, div0);
			append(div0, a0);
			append(div0, t0);
			append(div0, a1);
			append(nav, t3);
			append(nav, div3);
			append(div3, div1);
			if (if_block0) if_block0.m(div1, null);
			append(div3, t4);
			append(div3, div2);
			if (if_block1) if_block1.m(div2, null);
			insert(target, t5, anchor);
			insert(target, main, anchor);
			mount_component(route, main, null);
			append(main, t6);
			mount_component(privateroute0, main, null);
			append(main, t7);
			mount_component(privateroute1, main, null);
			append(main, t8);
			mount_component(privateroute2, main, null);
			append(main, t9);
			mount_component(privateroute3, main, null);
			append(main, t10);
			mount_component(privateroute4, main, null);
			append(main, t11);
			mount_component(privateroute5, main, null);
			current = true;

			if (!mounted) {
				dispose = listen(a1, "click", /*click_handler*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (/*$user*/ ctx[1]) {
				if (if_block0) {
					if (dirty & /*$user*/ 2) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div1, null);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*$user*/ ctx[1]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*$user*/ 2) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (dirty & /*navbar_active*/ 1) {
				toggle_class(div3, "is-active", /*navbar_active*/ ctx[0]);
			}

			const route_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				route_changes.$$scope = { dirty, ctx };
			}

			route.$set(route_changes);
			const privateroute0_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute0_changes.$$scope = { dirty, ctx };
			}

			privateroute0.$set(privateroute0_changes);
			const privateroute1_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute1_changes.$$scope = { dirty, ctx };
			}

			privateroute1.$set(privateroute1_changes);
			const privateroute2_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute2_changes.$$scope = { dirty, ctx };
			}

			privateroute2.$set(privateroute2_changes);
			const privateroute3_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute3_changes.$$scope = { dirty, ctx };
			}

			privateroute3.$set(privateroute3_changes);
			const privateroute4_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute4_changes.$$scope = { dirty, ctx };
			}

			privateroute4.$set(privateroute4_changes);
			const privateroute5_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute5_changes.$$scope = { dirty, ctx };
			}

			privateroute5.$set(privateroute5_changes);
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(route.$$.fragment, local);
			transition_in(privateroute0.$$.fragment, local);
			transition_in(privateroute1.$$.fragment, local);
			transition_in(privateroute2.$$.fragment, local);
			transition_in(privateroute3.$$.fragment, local);
			transition_in(privateroute4.$$.fragment, local);
			transition_in(privateroute5.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(route.$$.fragment, local);
			transition_out(privateroute0.$$.fragment, local);
			transition_out(privateroute1.$$.fragment, local);
			transition_out(privateroute2.$$.fragment, local);
			transition_out(privateroute3.$$.fragment, local);
			transition_out(privateroute4.$$.fragment, local);
			transition_out(privateroute5.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (detaching) detach(t5);
			if (detaching) detach(main);
			destroy_component(route);
			destroy_component(privateroute0);
			destroy_component(privateroute1);
			destroy_component(privateroute2);
			destroy_component(privateroute3);
			destroy_component(privateroute4);
			destroy_component(privateroute5);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let router;
	let current;

	router = new Router({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(router.$$.fragment);
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const router_changes = {};

			if (dirty & /*$$scope, navbar_active, $user_address, $user*/ 1031) {
				router_changes.$$scope = { dirty, ctx };
			}

			router.$set(router_changes);
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $user;
	let $is_DEV;
	let $user_address;
	component_subscribe($$self, user, $$value => $$invalidate(1, $user = $$value));
	component_subscribe($$self, is_DEV, $$value => $$invalidate(5, $is_DEV = $$value));
	component_subscribe($$self, user_address, $$value => $$invalidate(2, $user_address = $$value));
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	set_store_value(user, $user = urlParams.get('link'), $user);

	if (window.location.host == 'dev.local:9090') {
		set_store_value(is_DEV, $is_DEV = true, $is_DEV);
	}

	function handleLogout() {
		set_store_value(user, $user = null, $user);
	}

	let navbar_active = false;
	const click_handler = () => $$invalidate(0, navbar_active = !navbar_active);
	return [navbar_active, $user, $user_address, handleLogout, click_handler];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;